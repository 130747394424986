import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Icon, IconEdit } from '@flixbus/honeycomb-icons-react';
import RideInfoTag from './RideInfoTag';
import { TranslateContext } from '../../system/Translate';

class NotificationStatuses extends Component {
    static contextType = TranslateContext;

    constructor(props) {
        super(props);
        this.props = props;
    }

    getAcknowledgeBtn() {
        const { acknowledged, acknowledgement, line } = this.props.notification;
        const autoAcknowledged = acknowledgement?.autoAcknowledged;
        const { translate } = this.context;

        const tag = () => {
            if (!acknowledged && !autoAcknowledged) {
                return { label: translate('open'), type: 'default' };
            }
            if (acknowledged && !autoAcknowledged) {
                return { label: translate('fixed'), type: 'default' };
            }
            if (autoAcknowledged) {
                return { label: translate('auto'), type: 'default' };
            }
        };

        return (
            <RideInfoTag type={tag().type} lineColor={line?.lineColor}>
                {tag().label}
            </RideInfoTag>
        );
    }

    getErrorBtn() {
        const { line } = this.props.notification;
        return (
            <RideInfoTag lineColor={line?.lineColor}>
                {translate('attention')}
            </RideInfoTag>
        );
    }

    render() {
        const { notification } = this.props;
        const { acknowledged, syncError, acknowledgedAt } = notification;

        return (
            <div>
                {syncError ? this.getErrorBtn() : this.getAcknowledgeBtn()}

                {acknowledged && acknowledgedAt && (
                    <p>
                        <Icon InlineIcon={IconEdit} />{' '}
                        {acknowledgedAt.fromNow()}
                    </p>
                )}
            </div>
        );
    }
}

NotificationStatuses.propTypes = {
    notification: PropTypes.shape({
        syncAck: PropTypes.bool,
        id: PropTypes.string.isRequired,
        acknowledged: PropTypes.bool,
        acknowledgedByName: PropTypes.string,
        autoAcknowledged: PropTypes.bool,
        syncError: PropTypes.string,
        _links: PropTypes.object,
    }).isRequired,
};

export default NotificationStatuses;
