import { useEffect, useState, useRef } from 'react';

function useSticky() {
    const [isSticky, setSticky] = useState(false);
    const element = useRef(null);

    useEffect(() => {
        const debounceHandleScroll = () => {
            if (element.current !== null) {
                const offsetTop = element.current.offsetTop;
                const shouldStick = window.scrollY > offsetTop;

                shouldStick ? setSticky(true) : setSticky(false);
            }
        };

        window.addEventListener('scroll', debounceHandleScroll);

        return () => {
            window.removeEventListener('scroll', debounceHandleScroll);
        };
    }, []);

    return { isSticky, element };
}

export default useSticky;
