import { useEffect, useState, useRef, useContext, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import SockJsClient from 'react-stomp';

import { useApi } from '../../api';

import { ConfigProviderContext } from '../ConfigProvider';
import WDProviderContext from './WDProviderContext';
import { NotificationContext } from '../Notification';
import debounceCache from '../../helpers/debounceCache';
import WDMapper from './WDMapper';
import { TranslateContext } from '../../system/Translate';
import { formatDate } from '../../utils/date';
import FiltersProviderContext from '../FiltersProvider/FiltersProviderContext';
import usePrepareArchivedFilters from '../Results/hooks/usePrepareArchivedFilters';

const wdMap = new WDMapper();
export default function WDProvider({ children }) {
    const { filters } = useContext(FiltersProviderContext);
    const memoizedFilters = useMemo(
        () => ({
            ...filters,
            selectedDate: formatDate(new Date(), 'YYYY-MM-DD'),
        }),
        [filters]
    );
    const preparedFilters = usePrepareArchivedFilters(memoizedFilters);

    const isFirstRender = useRef(true);

    const [isLoadMore, setIsLoadMore] = useState(true);
    const [page, setPage] = useState(1);

    const [wds, request, reset] = useApi('post', `notifications/filtered?page=${page}&size=100`);
    const config = useContext(ConfigProviderContext);
    const notify = useContext(NotificationContext);

    const { translate } = useContext(TranslateContext);

    const [version, setVersion] = useState(0);
    const firstLoad = useRef(true);

    const onConnected = () => {
        notify({
            type: 'success',
            text: translate('websocket_connected'),
            expires: 30000,
        });
    };

    const onDisconnected = () => {
        notify({
            type: 'danger',
            text: translate('websocket_disconnected'),
            expires: 30000,
        });
    };

    const onNotificationReceived = (newNotification) => {
        if (typeof window.acWsDebug === 'function') {
            window.acWsDebug(newNotification);
        }

        debounceCache.addCache({ ...newNotification.payload, ws: true });
    };

    const alterVersion = useCallback(() => {
        setVersion(version + 1);
    }, [version, setVersion]);

    const update = (notification) => {
        wdMap.update(notification);
        alterVersion();
    };
    const merge = (data) => {
        wdMap.merge(data);
        alterVersion();
    };

    const loadMore = () => {
        setPage((prevPage) => prevPage + 1);
    };

    // fetch initial notifications
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        request(preparedFilters);
    }, [request, page, preparedFilters]);

    // reset pagination on filters update
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        } else {
            setPage(0);
            wdMap.reset();
        }
    }, [preparedFilters]);

    useEffect(() => {
        debounceCache.setCallback((updates) => {
            wdMap.set(updates);
            alterVersion();
        });
    }, [alterVersion]);

    useEffect(() => {
        if (wds.error) {
            notify({
                type: 'danger',
                text: `${translate('notifications_load_error')}: ${wds.error}`,
                expires: 10000,
            });
        }
    }, [wds.error]);

    // set initial notifications but filter them before
    useEffect(() => {
        if (wds.data?.content?.length > 0) {
            wdMap.set(wds.data.content, true);
            wdMap.init = wds.data.length;
            firstLoad.current = false;
            reset();
            alterVersion();
            setIsLoadMore(!wds.data.last);
        }
    }, [wds.data, firstLoad, alterVersion]);

    const providerValue = {
        loading: wds.pending,
        notifications: wdMap.getArray(),
        version,
        isLoadMore,
        update,
        merge,
        loadMore,
        init: wdMap.init,
    };
    return (
        <WDProviderContext.Provider value={providerValue}>
            <SockJsClient
                url={'/ws-connect'}
                topics={[config.websocketTopic]}
                onConnect={onConnected}
                onDisconnect={onDisconnected}
                onMessage={onNotificationReceived}
                debug={false}
            />
            {children}
        </WDProviderContext.Provider>
    );
}

WDProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
