import axios from 'axios';
import { useState, useEffect, useContext } from 'react';
import { Switch } from '@flixbus/honeycomb-react';
import { TranslateContext } from "../../system/Translate";
import { lineSettingUpdateAction } from "../../DataDog/actions";
import { getUser } from "../../auth";

const ChangeLineSetting = ({ lineCode, lineId, settingName, settingValue }) => {
    const [status, setStatus] = useState(settingValue);
    const [isLoading, setIsLoading] = useState(false);
    const user = getUser();
    const userName = user?.profile.name;
    const { translate } = useContext(TranslateContext);

    const handleChange = () => {
        if (!isLoading) {
            setIsLoading(true);

            axios({
                method: 'patch',
                url: `api/settings/lines/update`,
                data: [
                    {
                        lineId: lineId,
                        [settingName]: !status,
                    },
                ],
            }).then((response) => {
                setStatus(response?.data[0][settingName]);
                setIsLoading(false);
                lineSettingUpdateAction({
                    timestamp: new Date().getTime(),
                    userName,
                    settingName,
                    settingValue: response?.data[0][settingName],
                    lineCode,
                })
            });
        }
    };

    useEffect(() => {
        setStatus(settingValue);
    }, [settingValue]);

    return (
        <div className="change-line-setting">
            <Switch
                aria-label={status ? 'Yes' : 'No'}
                id={`switch-pax-rights_${lineId}${settingName}`}
                checked={status}
                onChange={handleChange}
                disabled={isLoading}
            />
            <span>{status ? translate('yes') : translate('no')}</span>
        </div>
    );
};

export default ChangeLineSetting;


