const USER_RULES = {
    cc_agent_internal: [],
    cc_agent_external: [],
    tc_agent_internal: [],
    shop_station_employee: [],
    tc_team_lead: ['settings-tab'],
    developer: ['settings-tab'] // all possible permissions should be mentioned here
};

export const USER_ROLE_TITLES = {
    cc_agent_internal: 'CC agent int',
    cc_agent_external: 'CC agent ext',
    tc_agent_internal: 'TC agent',
    shop_station_employee: 'Shop/station agent',
    tc_team_lead: 'TC team lead',
    developer: 'Developer'
};

export const USER_ACTION_TITLES = {
    'settings-tab': 'Access/edit settings'
};

export default USER_RULES;
