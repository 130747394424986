import React from 'react';
import Layout from '../Layout';
import Settings from '../Settings';

export default function SettingsPage() {
    return (
        <Layout>
            <Settings />
        </Layout>
    );
}
