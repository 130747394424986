import { useContext } from "react";
import {
    Accordion,
    Box,
    Grid,
    GridCol,
    Heading,
    Text,
    Button,
} from '@flixbus/honeycomb-react';
import { Icon, IconArrowLeft } from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from "../../system/Translate";

import './ErrorComponent.scss';

export default function ErrorUiComponent({ error, resetError = () => {} }) {
    const { translate } = useContext(TranslateContext);

    return (
        <Grid extraClasses="error-ui-component">
            <GridCol size={2} push={1}>
                <Button link onClick={resetError} aria-label="Back to App">
                    <Icon InlineIcon={IconArrowLeft} />
                    {translate('back_to_app')}
                </Button>
            </GridCol>
            <GridCol size={6}>
                <Box>
                    <Heading size={1}>{translate('error_happens')}</Heading>
                    <Text>{translate('known_issue')}</Text>
                    <Accordion title={translate('technical_details')}>
                        <Text>{String(error)}</Text>
                    </Accordion>
                </Box>
            </GridCol>
        </Grid>
    );
}
