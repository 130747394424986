import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@flixbus/honeycomb-react';
import { Icon, IconArrowDown, IconArrowUp } from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../../../system/Translate';

export default function FiltersPanel(props) {
    const { children } = props;
    const { translate } = useContext(TranslateContext);
    const [openPanel, setPanel] = useState(false);
    const cssClasses = classNames('autocontrol__filters-pane', {
        'autocontrol__filters-pane--active': openPanel,
    });
    return (
        <div className={cssClasses}>
            <Button
                link
                onClick={() => {
                    setPanel(!openPanel);
                }}
                extraClasses="autocontrol__filters-cta"
                aria-label={translate('filters')}
            >
                <Icon InlineIcon={openPanel ? IconArrowUp : IconArrowDown} />
                {translate('filters')}
            </Button>
            <div className="autocontrol__filters-pane-wrapper">{children}</div>
        </div>
    );
}
FiltersPanel.propTypes = {
    children: PropTypes.node,
};
FiltersPanel.defaultProps = { children: null };
