import React from 'react';
import Layout from '../Layout';
import AutomationsDetails from "../AutomationDetails";

export default function AutomationsDetailsPage() {
    return (
        <Layout>
            <AutomationsDetails />
        </Layout>
    );
}
