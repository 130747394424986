import * as FILTERS from '../components/Filters/filtersList';
import { ACKNOWLEDGED_STATUSES } from '../components/WDprovider/filterHelper';

const filterHelper = (notification, filterValue) => {
    const { acknowledged, acknowledgement } = notification;
    const autoAcknowledged = acknowledgement?.autoAcknowledged;

    return filterValue.some((val) => {
        if (
            !acknowledged &&
            !autoAcknowledged &&
            ACKNOWLEDGED_STATUSES[val] === ACKNOWLEDGED_STATUSES.OPEN
        ) {
            return true;
        } else if (
            acknowledged &&
            !autoAcknowledged &&
            ACKNOWLEDGED_STATUSES[val] === ACKNOWLEDGED_STATUSES.FIXED
        ) {
            return true;
        } else if (
            autoAcknowledged &&
            ACKNOWLEDGED_STATUSES[val] === ACKNOWLEDGED_STATUSES.AUTO
        ) {
            return true;
        } else {
            return false;
        }
    });
};

export default function filterFunction(filterParams, notification, filters) {
    if (notification.archived === true) {
        return false;
    }

    if (filters === undefined) {
        return;
    }

    const filterRes = filterParams.every((filter) => {
        const lookup = filter.split('.');
        const filterValue = filters[filter];

        const acknowledged = filter === FILTERS.ACKNOWLEDGED;
        const excludeConcessionOwner = filter === FILTERS.EXCLUDE_CONCESSION_OWNER_ID;
        const businessRegion = filter === FILTERS.BUSINESS_REGION;
        const excludeBusinessRegion = filter === FILTERS.EXCLUDE_BUSINESS_REGION;

        const value = lookup.reduce((a, b) => {
            if (excludeConcessionOwner) {
                return a[FILTERS.CONCESSION_OWNER_ID];
            } else if (businessRegion || excludeBusinessRegion) {
                return notification.line.regionId;
            } else {
                return a[b];
            }
        }, notification);

        let res = null;

        if (acknowledged) {
            res = filterHelper(notification, filterValue);
        } else if (businessRegion || excludeBusinessRegion) {
            const val = filterValue.includes(value);
            res = excludeBusinessRegion ? !val : val;
        } else {
            if (Array.isArray(filterValue)) {
                const val = filterValue.includes(value);
                res = excludeConcessionOwner ? !val : val;
            } else {
                const val = filterValue === value;
                res = excludeConcessionOwner ? !val : val;
            }
        }

        return res;
    });

    return filterRes;
}
