export const ACKNOWLEDGED_STATUSES = {
    FIXED: 'Fixed',
    OPEN: 'Open',
    AUTO: 'Auto',
};

export const filterHelper = (notification, filterValue) => {
    const { acknowledged, acknowledgement } = notification;
    const autoAcknowledged = acknowledgement?.autoAcknowledged;

    if (
        !acknowledged &&
        !autoAcknowledged &&
        ACKNOWLEDGED_STATUSES[filterValue] === ACKNOWLEDGED_STATUSES.OPEN
    ) {
        return true;
    } else if (
        acknowledged &&
        !autoAcknowledged &&
        ACKNOWLEDGED_STATUSES[filterValue] === ACKNOWLEDGED_STATUSES.FIXED
    ) {
        return true;
    } else if (
        autoAcknowledged &&
        ACKNOWLEDGED_STATUSES[filterValue] === ACKNOWLEDGED_STATUSES.AUTO
    ) {
        return true;
    } else {
        return false;
    }
};
