import React from 'react';
import './SelectWithIcon.scss';

// Honeycomb Select Component does not support Icons
const SelectWithIcon = ({ icon: IconComponent, children, iconStyles }) => {
    return (
        <div className="ac_select-with-icon__wrapper">
            <IconComponent
                className="ac_select-with-icon__icon"
                style={{...iconStyles}}
            />
            {children}
        </div>
    );
};

export default SelectWithIcon;