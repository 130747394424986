import React from 'react';
import PropTypes from 'prop-types';

export default function ListItem(props) {
    const { language, onClick } = props;
    return (
        <li className="language-switcher__item" key={language.code} onClick={onClick}>
            <span className="language-switcher__lng-text">
                {language.icon ? (
                    <img src={language.icon} className="language-switcher__img" alt={language.shortTitle} />
                ) : null}
                {language.title}
            </span>
        </li>
    );
}

ListItem.propTypes = {
    language: PropTypes.shape({
        icon: PropTypes.node,
        title: PropTypes.string,
        shortTitle: PropTypes.string,
        code: PropTypes.string,
    }).isRequired,
    onClick: PropTypes.func,
};

ListItem.defaultProps = {
    onClick: () => {},
};
