import { useEffect, useState } from "react";

const usePrepareArchivedFilters = (initialFilters) => {
    const [processedFilters, setProcessedFilters] = useState(initialFilters);

    useEffect(() => {
        const processFilters = (filters) => {
            const keyMap = {
                'type': 'types',
                'acknowledged': 'statuses',
                'line.lineId': 'lineIds',
                'ride.rideFromId': 'fromStationId',
                'ride.rideToId': 'toStationId',
                'concessionOwnerId': 'concessionOwnerIds',
                'not-concessionOwnerId': 'concessionOwnerExcludeIds',
                'selectedDate': 'date',
                'businessRegion': 'regionIds',
                'excludeBusinessRegion': 'regionIdsExclude',
            };

            return Object.keys(filters).reduce((acc, key) => {
                const newKey = keyMap[key] || key;
                acc[newKey] = filters[key];
                return acc;
            }, {});
        };

        const modifiedFilters = processFilters(initialFilters);
        setProcessedFilters(modifiedFilters);
    }, [initialFilters]);

    return processedFilters;
}

export default usePrepareArchivedFilters;