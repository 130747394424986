import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AppHeader from '../AppHeader';
import Home from '../Home';
import { AutomationsDetailsPage, AutomationsPage, SettingsPage } from '../Pages';
import ProtectedRoute from "../ProteectedRoute/ProtectedRoute";

function App() {
    return (
        <>
            <AppHeader />
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/settings" element={
                    <ProtectedRoute permissionRule={'settings-tab'}>
                        <SettingsPage />
                    </ProtectedRoute>
                } />
                <Route path="/automations" element={<AutomationsPage />} />
                <Route path="/automations/:id" element={<AutomationsDetailsPage />} />
            </Routes>
        </>
    );
}

export default App;
