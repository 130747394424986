import PropTypes from 'prop-types';
import FiltersProviderContext from './FiltersProviderContext';
import { useFilters } from '../Filters/hooks';
export default function FiltersProvider({ children }) {
    const [filters, addAction, deleteAction, resetAction, saveAction] =
        useFilters();

    const providerValue = {
        filters,
        addAction,
        deleteAction,
        resetAction,
        saveAction,
    };

    return (
        <FiltersProviderContext.Provider value={providerValue}>
            {children}
        </FiltersProviderContext.Provider>
    );
}

FiltersProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
