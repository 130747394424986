import { useCallback, useEffect, useContext, useState } from 'react';
import { useApi } from '../../api';
import WDProviderContext from '../WDprovider/WDProviderContext';
import { NotificationContext } from '../Notification';
import { DEFAULT_BROWSER_CACHE_MANAGER } from '@azure/msal-browser/dist/cache/BrowserCacheManager';

export default function useActions(id) {
    const { merge } = useContext(WDProviderContext);
    const [archive, archiveRequest, archiveReset] = useApi(
        'post',
        `notifications/${id}/archive`
    );
    const [revoke, revokeRequest, revokeReset] = useApi(
        'post',
        `notifications/${id}/acknowledge-revoke`
    );
    const [acknowledge, acknowledgeRequest, acknowledgeReset] = useApi(
        'post',
        `notifications/${id}/acknowledge`
    );
    const notify = useContext(NotificationContext);

    const reset = useCallback(() => {
        archiveReset();
        revokeReset();
        acknowledgeReset();
    }, [archiveReset, revokeReset, acknowledgeReset]);

    const actions = useCallback(
        (action) => {
            if (action === 'archive') {
                archiveRequest();
            }
            if (action === 'acknowledge-revoke') {
                revokeRequest();
            }
            if (action === 'acknowledge') {
                acknowledgeRequest();
            }
        },
        [archiveRequest, revokeRequest, acknowledgeRequest]
    );

    useEffect(() => {
        const error = [archive.error, revoke.error, acknowledge.error].find(
            (error) => error !== false
        );
        if (error) {
            notify({ type: 'danger', text: error.toString() });
            reset();
        }
    }, [archive.error, revoke.error, acknowledge.error]);

    useEffect(() => {
        const toUpdate = [archive.data, revoke.data, acknowledge.data].find(
            (data) => data !== null
        );
        if (toUpdate) {
            const { id, acknowledged, acknowledgement, archived } = toUpdate;
            merge({ id, acknowledged, acknowledgement, archived });
            reset();
        }
    }, [archive.data, revoke.data, acknowledge.data]);

    return [archive.pending || revoke.pending || acknowledge.pending, actions];
}
