export default function filtersReducer(state, action = { payload: {} }) {
    const { type, payload } = action;
    const { key, value, list } = payload || {};
    const newState = { ...state };

    switch (type) {
        case 'add':
            let addStateValue = newState[key];
            if (list) {
                if (Array.isArray(addStateValue)) {
                    !addStateValue.includes(value) && addStateValue.push(value);
                } else {
                    addStateValue = [value];
                }
                return { ...newState, [key]: addStateValue };
            }

            return { ...newState, [key]: value };
        case 'delete':
            let deleteStateValue = newState[key];
            if (Array.isArray(deleteStateValue)) {
                if (deleteStateValue && deleteStateValue.length === 1) {
                    delete newState[key];
                    return newState;
                }
                return {
                    ...newState,
                    [key]: deleteStateValue.filter((val) => val !== value),
                };
            }
            newState[key] !== undefined && delete newState[key];

            return newState;
        case 'reset':
            return {};
        default:
            return state;
    }
}
