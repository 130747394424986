import dayjs from 'dayjs';

export default {
    createdAt(direction = 'asc') {
        return function sort(a, b) {
            const aTime = new Date(a.createdAt).getTime();
            const bTime = new Date(b.createdAt).getTime();
            return direction === 'asc' ? bTime - aTime : aTime - bTime;
        };
    },
    departureDate(direction = 'asc') {
        return function sort(a, b) {
            // need to ignore timezone
            const aTime = dayjs(a.ride.rideDeparture).utc()
            const bTime = dayjs(b.ride.rideDeparture).utc()

            return direction === 'asc' ? aTime.diff(bTime) : bTime.diff(aTime);
        }
    },
    arrivalDate(direction = 'asc') {
        return function sort(a, b) {
            // need to ignore timezone
            const aTime = dayjs(a.ride.rideArrival).utc()
            const bTime = dayjs(b.ride.rideArrival).utc()

            return direction === 'asc' ? aTime.diff(bTime) : bTime.diff(aTime);
        }
    }
};