import React, { useEffect, useState } from 'react';
import {
    DataTable,
    DataTableHead,
    DataTableRow,
    DataTableSorter,
    Pager,
    PagerItem
} from '@flixbus/honeycomb-react';

export default function SettingsTable({ data, translate }) {
    const [rows, setRows] = useState(data ? data[0] : []);
    const [page, setPage] = useState(1);

    const [currentSorting, setCurrentSorting] = React.useState(['none', 'none']);
    const [sortedRows, setSortedRows] = useState([]);

    useEffect(() => {
        setRows(data ? data[0] : []);
    }, [data]);

    useEffect(() => {
        setSortedRows(rows);
    }, [rows])

    const sortRows = (sorting, comparator) => {
        const newlySortedRows = [...sortedRows].sort(comparator);

        if (sorting === 'descending') {
            setSortedRows(newlySortedRows.reverse());
            return;
        }

        setSortedRows(newlySortedRows);
    }


    const pagination = (
        <Pager Elem="button" aria-label="Table pagination">
            {data?.length ? (
                data.map((item, idx) => {
                    return (
                        <PagerItem
                            key={`page_${idx}`}
                            active={page === idx + 1}
                            onClick={() => goToPage(idx + 1)}
                        >
                            {idx + 1}
                        </PagerItem>
                    );
                })
            ) : (
                // otherwise Pager doesn't work
                <PagerItem active={page === 0} onClick={() => goToPage(0)}>
                    0
                </PagerItem>
            )}
        </Pager>
    );

    const goToPage = (page) => {
        setRows(data[page - 1] || []);
        setPage(page);
    };

    const getNumber = (lineCode) => {
        return Number(lineCode.replace(/\D/g, ''));
    };

    const sortByNumber = (sorting) => {
        setCurrentSorting(['number', sorting]);
        sortRows(sorting, (a, b) => {
            return getNumber(a.code) - getNumber(b.code);
        });
    };

    const sortByTitle = (sorting) => {
        setCurrentSorting(['title', sorting]);
        sortRows(sorting, (a, b) => {
            const aTitle = a.title.toUpperCase();
            const bTitle = b.title.toUpperCase();

            if (aTitle < bTitle) return -1;
            if (aTitle > bTitle) return 1;
            return 0;
        });
    };

    const headers = (
        <DataTableHead>
            <DataTableSorter aria-label="Sort settings by code" onSortingChange={sortByNumber}>
                {translate('settings__code')}
            </DataTableSorter>
            <DataTableSorter aria-label="Sort settings by title" onSortingChange={sortByTitle}>
                {translate('settings__title')}
            </DataTableSorter>
            <th>{translate('settings__automatic_delay')}</th>
            <th>{translate('pax_rights')}</th>
        </DataTableHead>
    )

    return sortedRows?.length ? (
        <div className="settings-page__table">
            <DataTable
                headers={headers}
                controls={pagination}
            >
                {sortedRows.map(({ code, title, autoDelayElement, paxRightsElement }) => {
                    return (
                        <DataTableRow key={`${code}-${title}`}>
                            <th scope="row">{code}</th>
                            <td>{title}</td>
                            <td>{autoDelayElement}</td>
                            <td>{paxRightsElement}</td>
                        </DataTableRow>
                    );
                })}
            </DataTable>
        </div>
    ) : (
        translate('settings__no_lines')
    );
}
