import { useReducer } from 'react';
import filtersReducer from './filtersReducer';
import useActionsCreator from './useActionsCreator';
import { parseURLParams } from "../../../utils/urlParams";

const DEFAULT_FILTERS = {
    acknowledged: ['OPEN'],
};

const STORAGE_FILTERS_KEY = 'ac-storage-filters';

export default function useFilters() {
    const getFilters = () => {
        const urlFilters = parseURLParams(window.location.href);
        if(Object.entries(urlFilters).length) {
            return urlFilters;
        }

        const storageValue = window.localStorage.getItem(STORAGE_FILTERS_KEY);
        return storageValue ? JSON.parse(storageValue) : DEFAULT_FILTERS;
    }

    const storageFilters = getFilters();
    const [filters, dispatcher] = useReducer(
        filtersReducer,
        storageFilters
    );
    const { addAction, deleteAction, resetAction } =
        useActionsCreator(dispatcher);

    const saveAction = (newValue) => {
        const filtersString = JSON.stringify(newValue);
        try {
            window.localStorage.setItem(STORAGE_FILTERS_KEY, filtersString);
        } catch (e) {
            console.error(e);
        }
    };

    return [filters, addAction, deleteAction, resetAction, saveAction];
}
