import { datadogRum } from "@datadog/browser-rum";
import { getUser } from "../auth";

const userObject = getUser();
const isProd = process.env.NODE_ENV === 'production';

datadogRum.init({
    applicationId: '285f1e28-5905-4987-ac53-908894cb9495',
    clientToken: 'pub4342b72f177ab18cd4c811152c60c70d',
    site: 'datadoghq.eu',
    service: 'fxt.ops.autocontrol-fe',
    env: isProd ? 'production' : 'development',
    version: '1.0.0',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: isProd ? 100 : 0,
    sessionReplaySampleRate: isProd ? 10 : 0,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});

if (userObject !== null) {
    const {
        profile: { name, roles },
        userName,
    } = userObject;
    datadogRum.setUser({ name, userName, roles });
    datadogRum.startSessionReplayRecording();
}