import React from 'react';
import useUserCan from "../../auth/useUserCan";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, permissionRule }) => {
    const userCanAccessRoute = useUserCan(permissionRule);

    return userCanAccessRoute ? children : <Navigate to="/" />;
};

export default ProtectedRoute;