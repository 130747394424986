import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import autocontrolConst from '../constants';

dayjs.extend(duration);

/**
 * Parse date and time in notifications
 * @param {Array} notifications
 * @return {Array} parsed notifications
 */
export const parseNotifications = (notifications = []) => {
    return notifications.map((n) => parseNotification(n));
};

/**
 * Parse date and time in one notification
 * @param {object} n - notification
 * @return {object} parsed notification
 */
export default function parseNotification(n) {
    if (n.metaInformation !== null) {
        n.delayString = getDelayString(
            n.autoAcknowledged,
            n.metaInformation.absoluteDelay
        );
    }

    return n;
}

/**
 * @param autoAcknowledged
 * @param absoluteDelay
 * @returns {string}
 */
const getDelayString = function (autoAcknowledged, absoluteDelay) {
    if (typeof absoluteDelay === 'undefined') {
        return '';
    }

    var delayString = '';

    if (autoAcknowledged) {
        delayString = setDelayTag(absoluteDelay);
    } else {
        var delay = dayjs.duration(absoluteDelay * 1000);

        delayString += delay.days() === 0 ? '' : delay.days() + 'd ';
        delayString += delay.hours() === 0 ? '' : delay.hours() + 'h ';
        delayString += delay.minutes() === 0 ? '' : delay.minutes() + 'm';
    }

    return delayString;
};

/**
 * Compare delay time with delays categories
 */
const setDelayTag = function (delay) {
    var category = autocontrolConst.maxDelayCategory;

    Object.keys(autocontrolConst.delayCategories).map((title) => {
        const limits = autocontrolConst.delayCategories[title];
        if (limits.min <= delay && delay < limits.max) {
            category = title;
            return false;
        } else {
            return true;
        }
    });

    return category;
};

export function uniqueMerge(collection = []) {
    const map = new Map(collection.map((item) => [item.id, item])).values();

    return [...map];
}

export const mergeUpdates = function (state, updates) {
    let updItems = uniqueMerge(updates);
    const newState = state.reduce((acc, item, index) => {
        const result = [...acc];
        let patchItem = { ...item };
        updItems = updItems.filter((incomeItem) => {
            if (incomeItem.id === item.id) {
                patchItem = { ...item, ...incomeItem };
                return false;
            }
            return true;
        });
        result.push(patchItem);
        return result;
    }, []);
    if (updItems.length > 0) {
        newState.unshift(...updItems);
    }
    return newState;
};
