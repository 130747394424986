import { Component } from 'react';
import PropTypes from 'prop-types';

import ErrorUiComponent from './ErrorUiComponent';

export default class ErrorComponent extends Component {
    constructor() {
        super();
        this.state = {
            hasError: false,
        };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, errorText: error.toString() };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
    }

    resetError = () => {
        this.setState({ hasError: false });
    };

    render() {
        const { children } = this.props;
        const { hasError, errorText } = this.state;
        return hasError ? (
            <ErrorUiComponent error={errorText} resetError={this.resetError} />
        ) : (
            children
        );
    }
}

ErrorComponent.propTypes = { children: PropTypes.node.isRequired };
