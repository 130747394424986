import { useState, useEffect, useCallback } from 'react';
import { autocompleteProcessor } from '../helpers';

/**
 * Hook that will filter provided array of objects
 * with given field
 * @func useFilter
 * @param {array} data arry of objects that will be filtered
 * @param {string} field field name to filter by
 * @returns {[{array}, {function}]} filtered data and filter handler
 */
export default function useFilter(data, field) {
    const [output, setOutput] = useState(data);

    useEffect(() => {
        setOutput(data);
    }, [data]);

    const filter = useCallback(
        (input) => {
            setOutput(autocompleteProcessor(input, field, data));
        },
        [setOutput, data, field]
    );

    return [output, filter];
}
