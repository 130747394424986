import { PublicClientApplication } from '@azure/msal-browser';

/**
 * config for auth microsoft
 * @constant
 * @name msalConfig
 */

export const clientId = 'bfb72150-6c78-485c-bc26-ef515a48c8fb';
export const uniqueId = 'd8d0ad3e-8bcf-48e9-9bb2-aee17c6c4fd5';

export const msalConfig = {
    auth: {
        clientId: clientId,
        authority:
            `https://login.microsoftonline.com/${uniqueId}`,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true,
    },
    system: {
        allowRedirectInIframe: true,
    },
};

export const scopes = [
    'https://einfachbusfahren.onmicrosoft.com/d8bb2c94-cbe5-4bd6-9a70-3b8e3f238fc1/user_impersonation',
];

export const msalPopupRequest = {
    scopes,
    redirectUri: window.location.origin,
};

export const msalSilentRequest = {
    scopes,
};

export const msalInstance = new PublicClientApplication(msalConfig);
