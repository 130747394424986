import React from 'react';
import Layout from '../Layout';
import Automations from '../Automations';

export default function AutomationsPage() {
    return (
        <Layout>
            <Automations />
        </Layout>
    );
}
