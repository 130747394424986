import { datadogRum } from '@datadog/browser-rum';

/**
 *
 * @param {object} context action
 * @param {string} context.timestamp action timestamp
 * @param {string} context.userName action username
 * @param {string} context.settingName action setting name
 * @param {string} context.settingValue action setting value
 * @param {string} context.lineCode action line code
 */
export function lineSettingUpdateAction(context) {
    datadogRum.addAction('line-setting-change', context);
}
