import React, { useContext, useEffect, useState } from 'react';
import { Button, Text } from "@flixbus/honeycomb-react";
import { Icon, IconArrowUp } from "@flixbus/honeycomb-icons-react";
import { TranslateContext } from "../../system/Translate";

import './ScrollToTop.scss';

const SHOW_BACK_TO_TOP_AFTER_PX = 2000;

const ScrollToTopComponent = () => {
    const [showButton, setShowButton] = useState(false);
    const { translate } = useContext(TranslateContext);

    useEffect(() => {
        const handleVisibility = () => {
            window.scrollY > SHOW_BACK_TO_TOP_AFTER_PX ? setShowButton(true) : setShowButton(false);
        }

        window.addEventListener('scroll', handleVisibility);

        return () => {
            window.removeEventListener('scroll', handleVisibility);
        }
    }, [])

    const handleClick = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    return showButton ? (
        <div className="scroll-to-top" onClick={handleClick}>
            <Button
                display="square"
                appearance="secondary"
                extraClasses={'scroll-to-top__button'}
            >
                <Icon InlineIcon={IconArrowUp} />
            </Button>
            <Text extraClasses="scroll-to-top__text">{translate('back-to-top')}</Text>
        </div>
    ) : null;
}

export default ScrollToTopComponent;