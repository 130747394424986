import React, { useContext } from 'react';
import ActiveItem from './ActiveItem';
import ListItem from './ListItem';
import { TranslateContext } from '../../system/Translate';

const defaultIcon = 'https://honeycomb.flixbus.com/dist/3.1.0/img/flags/svg/global.min.svg';

export default function LanguageSwitcher() {
    const { langsList, language, setLanguage } = useContext(TranslateContext);
    const activeLanguage = langsList[language];
    const onClickItem = (code) => () => {
        setLanguage(code);
    };
    return (
        <ul className="language-switcher">
            <li className="language-switcher__container">
                <ActiveItem
                    icon={activeLanguage.icon || defaultIcon}
                    title={activeLanguage.title}
                    text={activeLanguage.shortTitle}
                />
                <ul className="language-switcher__list">
                    {Object.keys(langsList).map((lngCode) => (
                        <ListItem language={langsList[lngCode]} key={lngCode} onClick={onClickItem(lngCode)} />
                    ))}
                </ul>
            </li>
        </ul>
    );
}
