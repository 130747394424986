export const parseURLParams = (url) => {
    const decodedURL = decodeURIComponent(url);
    const searchParams = new URLSearchParams(decodedURL.split('?')[1] || '');

    return [...searchParams].reduce((params, [key, value]) => {
        if (!params.hasOwnProperty(key)) {
            params[key] = [];
        }

        if (value.startsWith('[') && value.endsWith(']')) {
            const arrayValue = JSON.parse(value.replace(/&quot;/g, '"'));
            params[key].push(...arrayValue);
        } else {
            params[key].push(value);
        }

        return params;
    }, {});
};

export const objectToURLParams = (obj) => {
    return Object.entries(obj)
        .flatMap(([key, value]) => {
            // handle array values. E.G. status
            if (Array.isArray(value)) {
                return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(value))}`;
            }
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join('&');
}
