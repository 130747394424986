import React, { useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";
import { Heading } from "@flixbus/honeycomb-react";
import useAutomationDetails from "./hooks/useAutomationDetails";
import { TranslateContext } from "../../system/Translate";
import AlgorithmRepresentation from "./AlgorithmRepresentation";

export default function AutomationsDetails() {
    const { id } = useParams();
    const [automation, getAutomation] = useAutomationDetails(id);
    const { translate } = useContext(TranslateContext);

    useEffect(() => {
        getAutomation();
    }, [getAutomation]);

    return automation.data ? (
        <>
            <Heading size={2}>
                {automation.data.title}
            </Heading>
            <hr />
            <div className="automations-page">
                <AlgorithmRepresentation algorithmString={automation.data.stringRepresentation} />
            </div>
        </>
    ) : (
        <>{translate('automations_no_details_found')}</>
    )
}
