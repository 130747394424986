import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import {
    Header,
    HeaderBrand,
    HeaderWidgets,
    HeaderNavigation,
    NavItem,
    HeaderUserWidget,
    HeaderSubNavigation,
    HeaderBurgerMenu,
} from '@flixbus/honeycomb-react';
import LanguageSwitcher from '../LanguageSwitcher';
import { TranslateContext } from '../../system/Translate';
import useUserCan from "../../auth/useUserCan";
import USER_RULES, { USER_ACTION_TITLES, USER_ROLE_TITLES } from "../../auth/userRules";
import { Icon, IconProfile } from "@flixbus/honeycomb-icons-react";
import { getUser } from "../../auth";
import { msalInstance } from "../../auth";
import { clientId, uniqueId } from "../../auth/msalConfig";

export default function AppHeader() {
    const { translate } = useContext(TranslateContext);
    const canAccessSettingsTab = useUserCan('settings-tab');
    const user = getUser();
    const profileName = user?.profile.name;
    const roles = user?.profile.roles || [];

    const UserMenuRoleItem = ({ role }) => (
        <>
            <strong>
                <Icon InlineIcon={IconProfile} />
                {USER_ROLE_TITLES[role] || role}
            </strong>
            {USER_RULES[role]?.length > 0 ? (
                <UserMenuRoleRules role={role} />
            ) : (
                <div>{translate('user-menu.readonly-access')}</div>
            )}
        </>
    );

    const UserMenuRoleRules = ({ role }) => (
        <>
            <div>{translate('user-menu.actionslist-title')}</div>
            <ul>
                {USER_RULES[role].map((rule) => (
                    <li key={`${role}-${rule}`}>
                        {USER_ACTION_TITLES[rule] || rule}
                    </li>
                ))}
            </ul>
        </>
    );

    const MainNavigation = (
        <HeaderNavigation aria-label="Main">
            {/*Header navigation does not allow to use && operator or simply return null.*/}
            {/*UserCan tag is also not allowed.*/}
            {canAccessSettingsTab ? (
                    <NavItem to="/settings" RouterLink={Link}>
                        {translate('settings')}
                    </NavItem>
                ) :
                <NavItem style={{ display: 'none' }} children={<></>} />
            }

            <NavItem to="/automations" RouterLink={Link}>
                {translate('automations')}
            </NavItem>
        </HeaderNavigation>
    )

    const userMenu = () => {
        return roles.reduce((menuItems, role) => {
            const menuItem = {
                content: <UserMenuRoleItem role={role} />,
                href: '/',
                onClick: (e) => e.preventDefault(),
                key: role,
            };
            return [...menuItems, menuItem];
        }, []);
    };

    const handleLogOutClick = (instance) => {
        const homeAccountId = uniqueId + '.' + clientId;
        const logoutRequest = {
            account: instance.getAccountByHomeId(homeAccountId),
            mainWindowRedirectUri: window.location.origin,
            postLogoutRedirectUri: '/',
        };
        instance.logoutPopup(logoutRequest);
    }

    return (
        <Header extraClasses="header-wide">
            <HeaderBrand
                alt={translate('autocontrol')}
                href="/"
                RouterLink={Link}
                src="https://cdn-cf.cms.flixbus.com/drupal-assets/logos/flixbus.png"
            />
            <HeaderBurgerMenu
                openPanelLabel="Open main menu"
                closePanelLabel="Close main menu"
                panelId="main-menu-panel"
                applyWidgetContainer
            >
                {MainNavigation}
            </HeaderBurgerMenu>
            {MainNavigation}
            <HeaderWidgets
                style={{ display: 'flex' }}
            >
                <LanguageSwitcher />
                <HeaderUserWidget
                    subMenu={
                        <HeaderSubNavigation id="user-menu">
                            {userMenu().map((item) => (
                                <NavItem key={item.key} onClick={item.onClick}>
                                    {item.content}
                                </NavItem>
                            ))}
                            <NavItem
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleLogOutClick(msalInstance);
                                }}
                            >
                                Logout
                            </NavItem>
                        </HeaderSubNavigation>
                    }
                >
                    {profileName}
                </HeaderUserWidget>
            </HeaderWidgets>
        </Header>
    );
}

