import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react';
import { MainWrapper, ThemeWrapper } from '@flixbus/honeycomb-react';
import {
    Provider as RollbarProvider,
    ErrorBoundary as RollbarErrorBoundary,
} from '@rollbar/react';
import App from './components/App';
import { NotificationSystem } from './components/Notification';
import { msalInstance, withMsalAuth } from './auth';
import { ConfigProvider } from './components/ConfigProvider';
import { TranslateProvider } from './system/Translate';
import { ErrorUiComponent } from './components/ErrorComponent';
import rollbarConfig from './rollbar.conf';
import './index.scss';
import './DataDog';

const Protected = withMsalAuth(() => (
    <TranslateProvider>
        <ConfigProvider>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ConfigProvider>
    </TranslateProvider>
));

ReactDOM.render(
    <React.StrictMode>
        <RollbarProvider config={rollbarConfig}>
            <MsalProvider instance={msalInstance}>
                <MainWrapper>
                    <ThemeWrapper>
                        <NotificationSystem>
                            <RollbarErrorBoundary fallbackUI={ErrorUiComponent}>
                                <Protected />
                            </RollbarErrorBoundary>
                        </NotificationSystem>
                    </ThemeWrapper>
                </MainWrapper>
            </MsalProvider>
        </RollbarProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
