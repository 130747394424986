import React, { useCallback, useContext } from 'react';
import {
    Icon,
    IconSortUp,
    IconSort,
    IconExpand,
} from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../../system/Translate';
import { SortProviderContext } from '../SortProvider';

const MAP_DIR = ['asc', 'desc'];

export default function ResultsHeader({ sticky }) {
    const { translate } = useContext(TranslateContext);
    const { sort, sorting } = useContext(SortProviderContext);
    const [rule, direction] = sort.split(',');

    const getDirection = useCallback(
        (setRule) => {
            return setRule === rule
                ? MAP_DIR.find((i) => i !== direction)
                : MAP_DIR[0];
        },
        [rule, direction]
    );

    const getIcon = useCallback(
        (setRule) => {
            const InlineIcon = direction === MAP_DIR[0] ? IconSort : IconSortUp;
            return rule === setRule ? InlineIcon : IconExpand;
        },
        [rule, direction]
    );

    return (
        <div
            className={`autocontrol-results-header${
                sticky ? ' autocontrol-results-header--with-sticky-header' : ''
            }`}
        >
            <div className="results-col results-col--line">
                <div className="results-col__title results-col--sorting">
                    {translate('line')}
                </div>
            </div>
            <div className="results-col results-col--dep">
                <div
                    className="results-col__title"
                >
                    <span
                        onClick={() => {
                        sorting('departureDate', getDirection('departureDate'));
                    }}
                    >
                        {translate('departure')}
                        <Icon InlineIcon={getIcon('departureDate')} />
                    </span>
                    &rarr;
                    <span
                        onClick={() => {
                            sorting('arrivalDate', getDirection('arrivalDate'));
                        }}
                    >
                        {translate('arrival')}
                        <Icon InlineIcon={getIcon('arrivalDate')} />
                    </span>
                </div>
            </div>
            <div className="results-col results-col--created">
                <div
                    className="results-col__title"
                    onClick={() => {
                        sorting('createdAt', getDirection('createdAt'));
                    }}
                >
                    {translate('created_at')}
                    &nbsp;
                    <Icon InlineIcon={getIcon('createdAt')} />
                </div>
            </div>
            <div className="results-col results-col--type">
                <div className="results-col__title">{translate('type')}</div>
            </div>
            <div className="results-col results-col--status">
                <div className="results-col__title">
                    {translate('ack_status')}
                </div>
            </div>
            <div className="results-col results-col--actions"></div>
        </div>
    );
}
