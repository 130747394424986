/**
 * Generates list of options (for Autocomplete) with predefined structure
 * @func generateOptions
 * @param {object[]} data input list of items to create options list
 * @param {object} map {outputKey: inputKey} map input keys with output
 * @param {object} inject {key: value} this will be added to each item in output
 * @returns {array}
 */
export default function generateOptions(data, map, inject) {
    return data.map((item) =>
        Object.keys(map).reduce((acc, key) => {
            const result = { ...acc };

            result[key] = item[map[key]];

            return { ...result, ...inject };
        }, {})
    );
}
