import React, {useCallback, useContext, useEffect, useState} from "react";
import { Heading, Link } from "@flixbus/honeycomb-react";
import { TranslateContext } from "../../system/Translate";
import useAutomationsList from "./hooks/useAutomationsList";
import AutomationsTable from "./AutomationsTable";
import { Icon, IconNewTab } from "@flixbus/honeycomb-icons-react";

import './automations.scss';

const ITEMS_PER_PAGE = 250;

export default function Automations() {
    const { translate } = useContext(TranslateContext);
    const [automations, getAutomations] = useAutomationsList();
    const [data, setData] = useState([]);

    useEffect(() => {
        getAutomations();
    }, [getAutomations]);

    const prepareRowsData = useCallback((data) => {
        return data.reduce((resultArray, automation, index) => {
            const pagesChunkIndex = Math.floor(index / ITEMS_PER_PAGE);

            if (!resultArray[pagesChunkIndex]) {
                resultArray[pagesChunkIndex] = []; // start a new chunk of pages
            }

            resultArray[pagesChunkIndex].push({
                    title: automation.title,
                    subscribedTo: automation.subscribedTo.split(".").at(-1),
                    linkElement: <Link target="_blank" href={"/automations/"+automation.title}><Icon InlineIcon={IconNewTab} /></Link>
            });

            return resultArray;
        }, []);
    }, []);

    const preparedData = useCallback(() => {
        if (automations.data) {
            return prepareRowsData([...automations.data]);
        } else {
            return [];
        }
    }, [automations.data]);

    const initiateData = useCallback(() => {
        if (preparedData !== null) {
            setData(preparedData);
        }
    }, [preparedData]);

    useEffect(() => {
        if (automations.data !== null) {
            initiateData();
        }
    }, [automations.data]);

    return (
        <>
            <Heading size={2}>
                {translate('automations__list')}
            </Heading>
            <hr />
            <div className="automations-page">
                <AutomationsTable translate={translate} data={data} />
            </div>
        </>
    );
}
