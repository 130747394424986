/**
 * Matches search entry with value of key in an object of array item
 * @func autocompleteProcessor
 * @param {string} search string to search matches with
 * @param {string} key key of object to match in
 * @param {array} data the list filters and sort matches
 * @returns {array} array with items
 */

export default function autocompleteProcessor(search, key, data) {
    const searchNormalize = search.replace(/(\[|\]|\(|\)|\*|\.|\^|\{|\}|\+)/g, '\\$1');
    const exp = new RegExp(`(?:\\b|\\s)${searchNormalize}`, 'i');

    const result = data.reduce((acc, item) => {
        const result = [...acc];
        const searchValue = item[key];
        const searchIndex = searchValue?.search(exp);

        if (searchIndex !== -1) {
            const patchItem = { ...item, index: searchIndex };
            result.push(patchItem);
        }

        return result;
    }, []);

    return result.sort((a, b) => a.index - b.index);
}
