import React, { useEffect, useState } from 'react';
import { DataTable, DataTableHead, DataTableRow } from '@flixbus/honeycomb-react';

export default function AutomationsTable({ data, translate }) {
    const [rows, setRows] = useState(data ? data[0] : []);
    const headers = (
        <DataTableHead>
            <th>{translate('automations__title')}</th>
            <td>{translate('automations__subscribe_to')}</td>
            <td>{translate('automations__actions')}</td>
        </DataTableHead>
    )

    useEffect(() => {
        setRows(data ? data[0] : []);
    }, [data]);

    return rows?.length ? (
        <div className="automations-page__table">
            <DataTable
                headers={headers}
            >
                {rows.map(({ title, subscribedTo, linkElement }) => (
                     <DataTableRow key={title}>
                        <th scope="row">{title}</th>
                        <td>{subscribedTo}</td>
                        <td>{linkElement}</td>
                    </DataTableRow>
                ))}
            </DataTable>
        </div>
    ) : (
        translate('automations__no_items')
    );
}
