import React, { useContext, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { Text, Grid, GridCol, Spinner } from '@flixbus/honeycomb-react';
import {
    Icon,
    IconCircle,
    IconMagnifier,
} from '@flixbus/honeycomb-icons-react';

import { WDProviderContext } from '../WDprovider';
import { FiltersProviderContext } from '../FiltersProvider';
import { NotificationContext } from '../Notification';
import { TranslateContext } from '../../system/Translate';

import { useFiltersOptions } from './hooks';

import { FiltersPanel, FiltersCloud, Filters } from '../Filters';
import { ListPicker } from '../FormElements';
import { objectToURLParams } from "../../utils/urlParams";

const renderOption = (option, t) => {
    return (
        <Text className="ride-search-suggestions-option">
            <span className="ride-search-suggestions-option__title">
                <Icon InlineIcon={IconCircle} /> {option.title}
            </span>
            <small>{t(option.type)}</small>
        </Text>
    );
};

const FiltersWrapperMemoized = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const wds = useContext(WDProviderContext);
    const fpc = useContext(FiltersProviderContext);
    const notify = useContext(NotificationContext);
    const { translate, language } = useContext(TranslateContext);
    const [options, optionsRequest] = useFiltersOptions();

    useEffect(() => {
        optionsRequest();
    }, [optionsRequest]);

    const { filters, addAction, resetAction, saveAction: saveFilters } = fpc;

    const filtersPrev = useRef();
    const firstLoad = useRef(true);

    const resetFiltersAction = resetAction();

    const resetFilters = () => {
        resetFiltersAction();
    };

    const addFiltersToSearchQuery = (filters) => {
        const params = objectToURLParams(filters)
        const url = `${location.pathname}?${params}`;
        navigate(url); // add filter to the url
    }

    useEffect(() => {
        const filterParams = Object.keys(filters);

        if (filters && filterParams?.length > 0 && options.data) {
            firstLoad.current = false;
        }

        if (!firstLoad.current) {
            const prev = filtersPrev.current;
            const cur = JSON.stringify(filters);

            if (prev !== cur) {
                filtersPrev.current = JSON.stringify({ ...filters });

                addFiltersToSearchQuery(filters);
                saveFilters(filters);
            }
        }
    }, [wds.notifications, filters, options.data, saveFilters]);

    useEffect(() => {
        if (options.pending === true) {
            notify({ text: translate('filters_are_loading'), expires: 5000 });
        }
    }, [options.pending, notify]);

    return (
        <div className="autocontrol-content-block autocontrol-content-block--section">
            {!options.data ? (
                <Spinner size="md" />
            ) : (
                <>
                    <FiltersCloud options={options.data} reset={resetFilters} />
                    <div className="autocontrol__filters-wrapper">
                        <Grid align="bottom">
                            <GridCol size={10} xl={11}>
                                <ListPicker
                                    withoutInputLabel
                                    label={translate('pick_options')}
                                    ariaLabel={translate('pick_options')}
                                    id="filters"
                                    InlineIcon={IconMagnifier}
                                    options={options?.data?.listPickerOptions}
                                    onChange={(value, distributed) => {
                                        addAction(distributed, true)(value);
                                    }}
                                    value=""
                                    shouldResetValue={() => true}
                                    distributeBy="type"
                                    renderOption={(option) =>
                                        renderOption(option, translate)
                                    }
                                    extraClasses="autocontrol__picker"
                                />
                            </GridCol>
                        </Grid>
                        <FiltersPanel>
                            <Filters
                                options={options?.data}
                                language={language}
                            />
                        </FiltersPanel>
                    </div>
                </>
            )}
        </div>
    );
};

export const FiltersWrapper = React.memo(FiltersWrapperMemoized, () => true);
