import React, { useEffect, useReducer, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Notification, NotificationContainer } from '@flixbus/honeycomb-react';
import NotificationContext from './context';

function id() {
    function rnd() {
        return Math.random().toString(16).replace('0.', '');
    }
    return [].concat(rnd(), rnd(), rnd(), rnd()).join('-');
}

const reducer = (state, action) => {
    const { type, payload } = action;
    const { notifications } = state;
    switch (type) {
        case 'show':
            return {
                ...state,
                notifications: notifications.concat({
                    ...payload,
                    id: id(),
                    expired: new Date().getTime() + (payload.expires || 3000),
                }),
            };
        case 'hide':
            return {
                ...state,
                notifications: notifications.filter(
                    (item) => item.id !== payload
                ),
            };
        case 'add-timer':
            return {
                ...state,
                timers: state.timers.push(payload),
            };
        default:
            return { ...state };
    }
};

let timer;

export default function NotificationSystem(props) {
    const { children } = props;
    const [state, dispatch] = useReducer(reducer, { notifications: [] });

    const { notifications } = state;

    function notify(action, payload) {
        dispatch({
            type: action,
            payload,
        });
    }

    const provideNotify = useCallback((payload) => {
        dispatch({ type: 'show', payload });
    }, []);

    useEffect(() => {
        /**
         * @todo
         * while error messages shown Interval won't cleared
         */
        clearInterval(timer);

        if (notifications.length > 0) {
            timer = setInterval(() => {
                notifications.forEach((notification) => {
                    const date = new Date();
                    // hide those messages that was shown more than 3000 seconds exclude error messages
                    if (notification.expired < date.getTime()) {
                        notify('hide', notification.id);
                    }
                });
            }, 500);
        }
        return () => {
            clearInterval(timer);
        };
    }, [notifications]);

    return (
        <NotificationContext.Provider value={provideNotify}>
            {children}
            {notifications.length > 0 && (
                <NotificationContainer>
                    {notifications.map((notification) => (
                        <Notification
                            key={notification.id}
                            appearance={notification.type || null}
                            closeProps={{
                                onClick: () => {
                                    notify('hide', notification.id);
                                },
                                // TranslateContext is not available here
                                'aria-label': 'Close',
                            }}
                        >
                            {notification.text}
                        </Notification>
                    ))}
                </NotificationContainer>
            )}
        </NotificationContext.Provider>
    );
}

NotificationSystem.propTypes = {
    children: PropTypes.node.isRequired,
};
