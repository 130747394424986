import React from 'react';

import { WDProvider } from '../WDprovider';
import { FiltersProvider } from '../FiltersProvider';
import { SortProvider } from '../SortProvider';

import { ResultsPage } from '../Pages';

export default function Home() {
    return (
        <FiltersProvider>
            <WDProvider>
                <SortProvider>
                    <ResultsPage />
                </SortProvider>
            </WDProvider>
        </FiltersProvider>
    );
}
