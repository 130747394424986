/**
 * provide propper array from object {'id': 'title'}
 * @func normalizeConfigEntries
 * @param {object} entry
 * @param {function} callback (key, value)=>{}
 * @returns {object[]} {id: [id], title: 'title'}
 */
export default function normalizeConfigEntries(entry, callback) {
    const entries = [];
    Object.keys(entry).forEach((key) => {
        if (typeof callback === 'function') {
            return callback(key, entry[key]);
        }

        if (entry[key]) {
            if (Object(entry[key]).hasOwnProperty('title')) {
                if (entry[key].title !== null) {
                    const { title, code, id } = entry[key];
                    const newEntry = {
                        title: title && code ? `${code}: ${title}` : title,
                        id: id,
                    };
                    entries.push(newEntry);
                }
            } else {
                entries.push({ title: entry[key], id: key });
            }
        }
    });

    return entries;
}
