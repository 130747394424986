const autocontrolConst = {
    stations: {
        1: 'Berlin central bus station',
        2: 'Zurich',
        3: 'Constance Döbeleplatz',
        4: 'Constance Allmannsdorf',
        5: 'Meersburg',
        8: 'Freiburg (i.Br.)',
        9: 'Friedrichshafen',
        10: 'Munich central bus station',
        11: 'Titisee',
        12: 'Frankfurt central station',
        13: 'Darmstadt',
        14: 'Heidelberg',
        15: 'Rust (Europa park)',
        16: 'Lörrach',
        17: 'Stuttgart Vaihingen',
        18: 'Ludwigshafen',
        19: 'Mannheim',
        21: 'Nuremberg',
        22: 'Kehl',
        23: 'Strasbourg',
        24: 'Cologne Nord, Leverkusen',
        25: 'Düsseldorf',
        26: 'Karlsruhe',
        27: 'Heilbronn Neckarsulm',
        28: 'Würzburg',
        29: 'Suhl',
        30: 'Leipzig central station',
        31: 'Titisee Badeparadies',
        32: 'Speyer',
        33: 'Schwetzingen',
        34: 'Alsfeld Pfefferhöhe',
        35: 'Bad Hersfeld (train)',
        36: 'Hamburg',
        37: 'Celle',
        38: 'Frankfurt Airport',
        39: 'Bensheim',
        40: 'Bergen',
        41: 'Brunswick',
        42: 'Bayreuth (train)',
        43: 'Münchberg',
        44: 'Lauf',
        45: 'Kulmbach',
        46: 'Göttingen',
        47: 'Hildesheim',
        48: 'Wolfenbüttel',
        49: 'Dortmund',
        50: 'Bochum',
        51: 'Barsinghausen',
        52: 'Essen',
        53: 'Coblenz',
        54: 'Leverkusen (prev. Cologne-Deutz)',
        55: 'Augsburg',
        56: 'Bünde',
        57: 'Rostock central bus station',
        58: 'Warnemünde Strand',
        59: 'Warnemünde Werft',
        60: 'Lindau',
        61: 'Stuttgart Zuffenhausen',
        63: 'Herzberg',
        64: 'Hanover',
        65: 'Nordhausen',
        66: 'Coburg',
        67: 'Bamberg train (Ludwigst.)',
        68: 'Erlangen Hugenottenplatz',
        69: 'Osterode',
        70: 'Magdeburg',
        71: 'Halle',
        72: 'Norden',
        73: 'Norddeich',
        74: 'Neßmersiel (Drosselstraße)',
        75: 'Neßmersiel (Strandstraße)',
        76: 'Dornumersiel',
        77: 'Bensersiel',
        78: 'Reutlingen central st.',
        79: 'Tübingen',
        80: 'Esens',
        81: 'Neuharlingersiel',
        82: 'Carolinensiel',
        83: 'Harlesiel',
        84: 'Schillig',
        85: 'Horumersiel',
        86: 'Hooksiel',
        87: 'Wilhelmshaven',
        88: 'Oldenburg',
        89: 'Bremen',
        90: 'Chemnitz',
        91: 'Wuppertal',
        92: 'Luxembourg central station',
        93: 'Luxembourg Kirchberg',
        94: 'Trier train station',
        95: 'Kaiserslautern',
        101: 'Oberstdorf',
        111: 'Fischen',
        121: 'Jena central bus station',
        131: 'Weimar central station',
        141: 'Sonthofen',
        151: 'Kempten',
        161: 'Memmingen central bus station',
        171: 'Heidenheim',
        181: 'Aalen',
        191: 'Melle',
        201: 'Bad Oeynhausen',
        211: 'Osnabrück',
        221: 'Brilon',
        231: 'Münster',
        241: 'Dresden Neustadt',
        251: 'Dresden central station',
        261: 'Altenbüren',
        271: 'Bestwig',
        291: 'Mainz',
        301: 'Erlangen',
        311: 'Oberhausen central station',
        321: 'Saarbrücken [ALT]',
        331: 'Neunkirchen',
        341: 'Homburg',
        351: 'Zweibrücken',
        361: 'Pirmasens',
        371: 'Landau',
        381: 'Neheim',
        391: 'Arnsberg',
        401: 'Kassel Wilhelmshöhe',
        411: 'Meschede',
        421: 'Marsberg',
        431: 'Olsberg',
        441: 'Jever',
        451: 'Schortens-Heidmühle',
        461: 'Wittmund',
        471: 'Berlin SXF',
        481: 'Berlin Südkreuz',
        491: 'Delmenhorst',
        501: 'Bonn',
        511: 'Kiel',
        531: 'Friedrichshafen SEEhotel',
        541: 'Siegen',
        551: 'Kaltenkirchen',
        561: 'Saarbrücken',
        581: 'Wiesbaden',
        591: 'Mönchengladbach',
        601: 'Lindau',
        611: 'Berlin TXL, A/B',
        621: 'Munich Airport',
        631: 'Garmisch-Partenkirchen',
        641: 'Scharnitz',
        651: 'Seefeld',
        661: 'Jülich',
        671: 'Innsbruck Südbahnstraße',
        681: 'Bregenz',
        684: 'Ulm',
        694: 'Gießen Mathematikum',
        704: 'Aachen West',
        714: 'Immenstadt',
        724: 'Neu-Ulm',
        734: 'Emden central bus station',
        744: 'Erfurt',
        754: 'Aschaffenburg',
        764: 'Emden Außenhafen',
        774: 'Euskirchen',
        784: 'Bad Segeberg',
        794: 'Duisburg',
        804: 'Gera',
        814: 'Lübeck',
        824: 'Neumünster',
        834: 'Schwerin',
        844: 'Offenburg Messeplatz',
        854: 'Leer',
        864: 'Dorsten',
        874: 'Soest',
        884: 'Pforzheim',
        894: 'Regensburg central station',
        904: 'Zwickau Stadthalle',
        914: 'Berlin BETA',
        924: 'Greifswald',
        934: 'Stralsund',
        944: 'Bergen auf Rügen',
        954: 'Binz',
        964: 'Sellin',
        974: 'Baabe',
        984: 'Göhren Rügen',
        994: 'Hof',
        1004: 'Fulda Nord',
        1014: 'Merzig',
        1024: 'Krefeld',
        1034: 'Stuttgart Kirchheim unter Teck',
        1044: 'Merseburg',
        1054: 'Goslar',
        1074: 'Bottrop',
        1084: 'Gelsenkirchen',
        1094: 'Herne',
        1104: 'Selb',
        1114: 'Weiden in der Oberpfalz',
        1124: 'Schwandorf',
        1144: 'Deggendorf',
        1154: 'Passau',
        1164: 'Aschersleben',
        1174: 'Quedlinburg',
        1184: 'Wernigerode',
        1194: 'Helmstedt',
        1204: 'Weißenfels',
        1214: 'Zeitz',
        1224: 'Berlin Alexanderplatz',
        1234: 'Leipzig Messe',
        1244: 'Hamelin',
        1254: 'Blankenburg',
        1264: 'Bad Harzburg',
        1274: 'Seesen',
        1284: 'Hamm',
        1304: 'Milan (Lampugnano)',
        1314: 'Panketal',
        1324: 'Schweinfurt',
        1334: 'Medebach',
        1344: 'Winterberg',
        1354: 'Siedlinghausen',
        1364: 'Bödefeld',
        1374: 'Oberhausen CentrO',
        1384: 'Arnhem',
        1394: 'Utrecht',
        1404: 'Amsterdam Rietlandpark',
        1414: 'Überlingen',
        1424: 'Paderborn central station',
        1434: 'Bielefeld',
        1444: 'Mainau (Lake Constance)',
        1454: 'Eisenach',
        1464: 'Isselburg',
        1474: 'Braunau',
        1484: 'Simbach',
        1494: 'Prague ÚAN Florenc',
        1504: 'Vienna Stadion Center',
        1514: 'Hollabrunn',
        1524: 'Tettnang',
        1534: 'Halberstadt',
        1544: 'Uplengen',
        1554: 'Groningen',
        1564: 'Marburg',
        1574: 'Bad Zwesten',
        1604: 'Dornbirn',
        1614: 'Chur',
        1624: 'Bellinzona',
        1634: 'Basel central station',
        1644: 'Lugano',
        1654: 'Schleiz',
        1664: 'Salzburg',
        1674: 'Ingolstadt',
        1684: 'Memmingen airport',
        1694: 'Leutkirch',
        1704: 'Wangen',
        1714: 'Himmelkron',
        1715: 'Bayreuth Uni',
        1725: 'Ravensburg',
        1735: 'Vienna Matzleinsdorferplatz',
        1745: 'Vienna Felberstrasse',
        1755: 'Graz Murpark',
        1765: 'Graz (Girardigasse)',
        1775: 'Meran (Merano)',
        1785: 'Marling (Marlengo)',
        1795: 'Tscherms (Cermes)',
        1805: 'Lana',
        1815: 'Terlan (Terlano)',
        1825: 'Klausen (Chiusa)',
        1835: 'Eppan (Appiano)',
        1845: 'Bozen (Bolzano)',
        1855: 'Brixen (Bressanone)',
        1865: 'Sterzing (Vipiteno)',
        1875: 'Innsbruck Südbahnstraße',
        1885: 'Amberg',
        1895: 'Amstetten',
        1905: 'Ansbach',
        1915: 'Attnang-Puchheim',
        1925: 'Berlin Ostbf.',
        1935: 'Bremen BRE',
        1945: 'Bremerhaven',
        1955: 'Bratislava BTS',
        1965: 'Bratislava Einsteinova',
        1975: 'Bratislava Most SNP',
        1985: 'Wrocław',
        1995: 'Bydgoszcz',
        2005: 'Brussels central train station',
        2015: 'Budapest',
        2025: 'Budweis (České Budějovice)',
        2035: 'Gdańsk',
        2045: 'The Hague',
        2055: 'Detmold',
        2065: 'Enschede',
        2075: 'Flensburg',
        2085: 'Florence',
        2095: 'Frankfurt Hahn',
        2105: 'Freiburg Pressehaus',
        2115: 'Gedser',
        2125: 'Göttingen Kauf Park',
        2135: 'Győr',
        2145: 'Hagen',
        2146: 'Hanau',
        2166: 'Katowice bus station',
        2176: 'Klagenfurt',
        2186: 'Copenhagen',
        2196: 'Kraków',
        2205: 'Leverkusen',
        2225: 'Linz',
        2235: 'Ljubljana',
        2245: 'Liège',
        2255: 'Maastricht',
        2265: 'Malmö',
        2275: 'Maribor',
        2285: 'Metz',
        2305: 'Mosonmagyarovar',
        2315: 'Neuss',
        2325: 'Oldenburg Pferdemarkt',
        2335: 'Oldenburg Uni',
        2345: 'Opole',
        2355: 'Padua',
        2365: 'Paris (Porte Maillot)',
        2375: 'Pilsen',
        2385: 'Poznań Górczyn',
        2395: 'Puttgarden',
        2405: 'Recklinghausen',
        2415: 'Rødbyhavn',
        2425: 'Rome',
        2435: 'Rostock ferry terminal',
        2445: 'Rothenburg',
        2455: 'Salzburg Flughafen',
        2465: 'Salzgitter',
        2475: 'Schwabach',
        2485: 'Singen',
        2495: 'St. Gallen',
        2505: 'Sankt Pölten',
        2515: 'Szczecin station',
        2525: 'Stuttgart Obertürkheim',
        2535: 'Trelleborg',
        2545: 'Trieste',
        2555: 'Venice (Tronchetto)',
        2565: 'Verona',
        2575: 'Villingen-Schwenningen',
        2595: 'Wels',
        2605: 'Vienna VIE',
        2615: 'Vienna Hütteldorf',
        2625: 'Wismar',
        2635: 'Zagreb',
        2645: 'Fulda Weimarer Straße',
        2655: 'Vienna Erdberg',
        2665: 'Kassel Kaufungen',
        2675: 'Bad Hersfeld (Hünfelder St.)',
        2685: 'Zwickau central station',
        2695: 'Paderborn Maspernplatz',
        2705: 'Offenburg, Güterstr.',
        2715: 'Amsterdam Sloterdijk',
        2725: 'Bamberg Bf-Vorplatz',
        2735: 'Frankfurt FRA1',
        2745: 'Weimar Atrium',
        2755: 'Jena central bus station (west)',
        2765: 'Heilbronn central station',
        2775: 'Graz central train station',
        2785: 'Stuttgart Airport',
        2795: 'Trier train (Kürenzer St.)',
        2805: 'Gießen (Licher Straße)',
        2815: 'Vienna Europaplatz',
        2825: 'Berlin Zoo',
        2835: 'Weil am Rhein',
        2845: 'Reutlingen central station',
        2855: 'Potsdam',
        2865: 'Lille',
        2875: 'Brussels-North station',
        2885: 'Basel Bad. Bf',
        2895: 'EuroAirport Basel',
        2905: 'Bad Bentheim',
        2925: 'Regensburg Rasthof',
        2928: 'Aurich',
        2938: 'Ahlbeck',
        2948: 'Anklam',
        2958: 'Bansin',
        2968: 'Heringsdorf',
        2978: 'Usedom (town)',
        2988: 'Dahme',
        2998: 'Grömitz',
        3008: 'Haffkrug',
        3018: 'Sierksdorf Hansa Park',
        3028: 'Kellenhusen',
        3038: 'Neustadt in Holstein',
        3048: 'Neustadt-Glewe',
        3058: 'Scharbeutz',
        3068: 'Timmendorfer Strand',
        3078: 'Bad Windsheim',
        3088: 'Forchheim',
        3098: 'Herzogenaurach',
        3108: 'Neustadt/Aisch',
        3118: 'Bad Doberan',
        3128: 'Elmenhorst',
        3138: 'Kühlungsborn Ost',
        3148: 'Kühlungsborn West',
        3158: 'Nienhagen',
        3168: 'Rethwisch',
        3178: 'Diedrichshagen',
        3188: 'Krausnick (Tropical Islands)',
        3198: 'Markdorf',
        3208: 'Immenstaad',
        3218: 'Hagnau',
        3228: 'Engen',
        3238: 'Hüfingen',
        3258: 'Brussels Gare du Midi',
        3268: 'Antwerp',
        3288: 'Berlin Alt-Tegel',
        3298: 'Graal-Müritz',
        3308: 'Dierhagen',
        3318: 'Wustrow',
        3328: 'Ahrenshoop',
        3338: 'Wieck, Darss',
        3348: 'Prerow',
        3358: 'Zingst',
        3368: 'Born, Darss',
        3378: 'Rotterdam CS',
        3388: 'Torino (Vittorio Emanuele)',
        3398: 'Rendsburg',
        3408: 'Schleswig',
        3418: 'Reims TGV',
        3438: 'Sierksdorf',
        3448: 'Hessisch Oldendorf',
        3458: 'Dülmen',
        3468: 'Hamminkeln',
        3478: 'Wesel',
        3488: 'Gütersloh',
        3498: 'Xanten',
        3508: 'Warendorf',
        3518: 'Borken',
        3528: 'Bocholt',
        3538: 'Gotha',
        3548: 'Worms',
        3558: 'Straubing',
        3568: 'Lunenburg',
        3578: 'Uelzen',
        3588: 'Gifhorn',
        3598: 'Ghent',
        3608: 'Löwenberg',
        3618: 'Gransee',
        3628: 'Fürstenberg',
        3638: 'Neustrelitz',
        3648: 'Neubrandenburg',
        3658: 'Lubmin',
        3668: 'Greifswald Klinikum',
        3678: 'Lübbenau/Spreewald',
        3688: 'Ibbenbüren',
        3698: 'Rheine',
        3708: 'Schüttorf',
        3718: 'Nordhorn',
        3728: 'Stade',
        3738: 'Bremervörde',
        3748: 'Beverstedt',
        3758: 'Mestre (Train station)',
        3768: 'Rheda-Wiedenbrück',
        3778: 'Emmerich',
        3788: 'Alsfeld train station',
        3798: 'Gelnhausen',
        3808: 'Wittichenau',
        3818: 'Kleinwelka',
        3828: 'Königswartha',
        3838: 'Groß Särchen',
        3848: 'Neschwitz',
        3858: 'Brescia',
        3868: 'Soltau',
        3878: 'Saalfeld',
        3888: 'Rudolstadt train station',
        3898: 'Uhlstädt',
        3908: 'Rudolstadt (Schwarza Traube)',
        3918: 'Dillenburg',
        3928: 'Gladenbach',
        3938: "Genoa (Fanti d'Italia)",
        3958: 'Piacenza',
        3968: 'Bologna',
        3978: 'Stockholm',
        3988: 'Hessisch Lichtenau',
        3998: 'Zschopau',
        4008: 'Wehretal',
        4018: 'Rimini',
        4028: 'Ancona',
        4038: 'Marienberg',
        4048: 'Pescara',
        4058: 'Foggia',
        4068: 'Aichach',
        4078: 'Bad Arolsen',
        4088: 'Bautzen',
        4108: 'Bari',
        4118: 'Kahla',
        4128: 'Orlamünde',
        4138: 'Ilmenau',
        4148: 'Clermont-Ferrand',
        4158: 'Soltau (Designer Outlet)',
        4168: 'Paris CDG (Terminal 3)',
        4178: 'Lausanne',
        4188: 'Bern',
        4198: 'Soltau (Heidepark)',
        4208: 'Geneva',
        4218: 'Limburg',
        4228: 'Landshut',
        4248: 'Béziers',
        4258: 'Biarritz',
        4268: 'Bitburg',
        4278: 'Böblingen',
        4288: 'BDX St-Jean',
        4298: 'Breda CS',
        4308: 'Bruges',
        4318: 'Carcassonne',
        4328: 'Chambéry',
        4338: 'Charleroi',
        4348: 'Charleroi',
        4368: 'Colmar',
        4378: 'Cottbus',
        4388: 'Cuxhaven',
        4398: 'De Panne',
        4408: 'Delft',
        4418: 'Deventer',
        4438: 'Dijon',
        4448: 'Frankenberg',
        4458: 'Geiselwind',
        4468: 'Görlitz',
        4478: 'Greifswald Wieck',
        4488: 'Greding',
        4498: 'Herzhausen',
        4508: 'Korbach',
        4518: 'La Rochelle',
        4528: 'Leuven',
        4538: 'Lingen',
        4548: 'Lyon Perrache',
        4558: 'Marseille Arenc',
        4568: 'Bourges',
        4578: 'Massy',
        4588: 'Meppen',
        4598: 'Minden',
        4608: 'Mittenwald',
        4618: 'Mons',
        4628: 'Munich Fröttmaning',
        4638: 'Montpellier',
        4648: 'Namur',
        4658: 'Nantes',
        4678: 'Metzingen',
        4688: 'Nijmegen',
        4698: 'Nîmes',
        4708: 'Merklingen',
        4718: 'Bad Urach',
        4728: 'Nürtingen',
        4738: 'Oss',
        4748: 'Pau',
        4758: 'Perpignan',
        4778: 'Rennes',
        4788: 'Rerik',
        4798: 'Rheinsberg',
        4808: 'Roermond',
        4818: 'Rotterdam Zuidplein',
        4828: 'Schrobenhausen',
        4838: 'Siegburg',
        4848: 'Springe',
        4858: 'Saint-Jean-de-Luz',
        4868: 'Tilburg',
        4878: 'Toulouse',
        4888: 'Trento',
        4898: 'Turin',
        4908: 'Weinsberg',
        4918: 'Wendlingen',
        4928: 'Wetter (Hessen)',
        4938: 'Winterthur',
        4948: 'London',
        4958: 'Mulhouse',
        4968: 'Grenoble',
        4978: 'Angers',
        5008: 'Aime',
        5018: 'Aix-en-Provence',
        5028: 'Albertville',
        5038: 'Amiens',
        5058: 'Annecy',
        5098: 'Arras',
        5108: 'Aubagne',
        5118: 'Auxerre',
        5138: 'Avranches',
        5158: 'Bayonne',
        5178: 'Belfort',
        5188: 'Besançon',
        5208: 'Boulogne-sur-Mer',
        5228: 'Bourg-Saint-Maurice',
        5238: 'Bressuire',
        5248: 'Brest',
        5268: 'Brive-la-Gaillarde',
        5288: 'Caen',
        5308: 'Calais',
        5318: 'Calais Terminal Ferry',
        5348: 'Châlons-en-Champagne',
        5378: 'Chateauroux',
        5408: 'Cholet',
        5428: 'Dieppe',
        5468: 'Dunkerque',
        5558: 'Hyères',
        5588: 'Le Havre',
        5598: 'Le Mans',
        5608: 'Lens',
        5618: 'Limoges',
        5628: 'Lorient',
        5658: 'Mâcon',
        5728: 'Neuenburg am Rhein',
        5738: 'Den Bosch',
        5748: 'Apeldoorn',
        5768: 'Assen',
        5778: 'Domburg',
        5788: 'Eindhoven',
        5798: 'Hengelo',
        5808: 'Hoogeveen Station',
        5818: 'Kampen',
        5828: 'Leiden',
        5838: 'Middelburg',
        5848: 'Oostkapelle',
        5858: 'Venlo Sint Urbanusweg',
        5868: 'Westkapelle',
        5878: 'Zwolle',
        5958: 'Peine',
        5998: 'Salou',
        6008: 'San Sebastiàn / Donostia',
        6018: 'Tarragona',
        6028: 'Valence France',
        6038: 'Lons-le-Saunier',
        6048: 'Eppingen',
        6058: 'Poitiers',
        6078: 'Flensburg central bus station',
        6088: 'Kruså',
        6098: 'Kolding',
        6108: 'Vejle',
        6118: 'Aarhus',
        6128: 'Augsburg central train station',
        6138: 'Bretten',
        6148: 'Sofia',
        6158: 'Saint-Étienne',
        6168: 'Tours',
        6178: 'Rouen',
        6188: 'Paris La Défense',
        6198: 'Lyon Part-Dieu',
        6208: 'Ferrara',
        6218: 'Naples',
        6228: 'Novara',
        6238: 'Bergamo (Bus Station)',
        6248: 'Reggio Emilia',
        6258: 'Grosseto',
        6268: 'Livorno',
        6278: 'Pisa',
        6288: 'Lecce',
        6298: 'Brindisi',
        6308: 'Modena',
        6318: 'Oberjoch',
        6328: 'Hopfgarten',
        6338: 'Westendorf',
        6348: 'Brixen im Thale',
        6358: 'Kirchberg',
        6368: 'Kitzbühel',
        6378: 'St. Johann i.T.',
        6388: 'Kaltenbach',
        6398: 'Zell am Ziller',
        6408: 'Ramsau',
        6418: 'Mayrhofen',
        6428: 'Ehrwald',
        6438: 'Lermoos',
        6448: 'Imst',
        6458: 'St. Anton',
        6468: 'Ötztal Bahnhof',
        6478: 'Oetz',
        6488: 'Umhausen',
        6498: 'Längenfeld',
        6508: 'Sölden',
        6518: 'Oberstdorf Fellhornbahn',
        6528: 'Zams',
        6538: 'See',
        6548: 'Kappl',
        6558: 'Ischgl',
        6568: 'Wirl',
        6578: 'Tourcoing',
        6588: 'Scheffau',
        6598: 'Ellmau',
        6608: 'Fieberbrunn',
        6618: 'Leogang Asitz',
        6628: 'Leogang Ort',
        6638: 'Zell am See',
        6648: 'Saalbach, Schattberg Exp.',
        6658: 'Saalbach, Schönleitenlifte',
        6668: 'Hinterglemm, Ellmauweg',
        6678: 'Hinterglemm, Zwölferkogelbahn',
        6688: 'Söll',
        6698: 'St. Johann i.T., Eichenhof',
        6708: 'Vienna (Karlsplatz)',
        6718: 'Hoyerswerda',
        6728: 'Spremberg',
        6748: 'Nancy',
        6758: 'Forbach',
        6768: 'Haren',
        6778: 'Toulon',
        6788: 'Asti',
        6798: 'Alessandria',
        6808: 'Saint-Dizier',
        6818: 'Breda Prinsenbeek',
        6828: 'Enschede UT',
        6838: 'Rotterdam Lombardijen',
        6848: 'Moûtiers',
        6858: 'Vierzon',
        6868: "Val d'Isère",
        6878: 'Séez',
        6888: 'Sainte-Foy-Tarentaise',
        6898: 'Vitry-Le-François',
        6908: 'Terneuzen',
        6918: 'Karlovy Vary (Terminál)',
        6928: 'Cheb',
        6938: 'Marktredwitz',
        6948: 'Bischofsgrün',
        6958: 'Hollfeld',
        6968: 'Haarlem',
        6978: 'Kolín',
        6988: 'Prague Main Railway Station',
        6998: 'Pardubice',
        7008: 'Zábřeh',
        7018: 'Olomouc',
        7028: 'Přerov',
        7038: 'Hulín',
        7048: 'Otrokovice',
        7058: 'Staré Město',
        7068: 'Hranice na Moravě',
        7078: 'Suchdol nad Odrou',
        7088: 'Studénka',
        7098: 'Ostrava-Svinov',
        7108: 'Ostrava hl.n. LEO',
        7118: 'Bohumín',
        7128: 'Dětmarovice',
        7138: 'Karviná',
        7148: 'Český Těšín',
        7158: 'Třinec',
        7168: 'Třinec',
        7178: 'Bystřice',
        7188: 'Čadca',
        7198: 'Žilina',
        7208: 'Vrútky',
        7218: 'Ružomberok Station',
        7228: 'Liptovský Mikuláš',
        7238: 'Štrba',
        7248: 'Poprad-Tatry',
        7258: 'Spišská Nová Ves',
        7268: 'Prešov Station',
        7278: 'Kysak',
        7288: 'Košice',
        7298: 'Fügen',
        7308: 'Schwendau',
        7318: 'Les Rousses',
        7328: 'Orléans',
        7338: 'Montbéliard',
        7348: 'Jihlava',
        7358: 'Znojmo',
        7368: 'Salzburg (train)',
        7378: 'Linz Hbf',
        7388: 'Béthune',
        7398: 'Parma',
        7408: 'Siena',
        7418: 'Quimper',
        7428: 'Vannes',
        7438: 'Paray-Le-Monial',
        7448: 'Saint-Quentin',
        7458: 'Deva',
        7468: 'Bruck (Mur)',
        7478: 'Graz Puntigam',
        7488: 'Oeynhausen',
        7498: 'Sankt Pölten, Mariazeller Str.',
        7508: 'Wr. Neustadt Hbf',
        7518: 'Wr. Neustadt, A2',
        7528: 'Banja Luka',
        7538: 'Gradiška',
        7548: 'Laktaši',
        7558: 'Biograd Motel',
        7568: 'Crikvenica',
        7578: 'Karlovac',
        7588: 'Opatija',
        7598: 'Rijeka',
        7608: 'Šibenik',
        7618: 'Split',
        7628: 'Trogir',
        7638: 'Zadar',
        7648: 'Arad',
        7658: 'Brașov',
        7668: 'Bukarest',
        7678: 'Făgăraș',
        7688: 'Lugoj',
        7698: 'Ploiești',
        7708: 'Sebeș',
        7718: 'Sibiu',
        7728: 'Timișoara Normandia',
        7738: 'Völklingen',
        7748: 'Tignes',
        7758: 'Tullnerfeld',
        7768: 'Cannes',
        7778: 'Nice Aéroport Terminal 2',
        7788: 'Cologne East, Refrath',
        7798: 'Cologne West, Kerpen',
        7807: 'Sète',
        7828: 'Poitiers Futuroscope',
        7838: 'Bratislava (central bus station)',
        7848: 'Hainburg Hauptplatz',
        7858: 'Hainburg Pfaffenbergweg',
        7868: 'Vienna (central station)',
        7878: 'Wolfsthal',
        7888: 'Hainburg Ungartor',
        7898: 'Ravenna',
        7908: 'Marseillan',
        7918: 'Pasewalk',
        7928: 'Amersfoort',
        7938: 'Eindhoven Airport',
        7948: 'Troisdorf',
        7958: 'Tabor',
        7968: 'Český Krumlov (Špičák)',
        7978: 'Český Krumlov (bus station)',
        7988: 'Nevers',
        7998: 'Moulins',
        8008: 'Disneyland Paris',
        8018: 'Prato',
        8028: 'Vicenza',
        8038: 'Marseille St. Charles',
        8058: 'Stuttgart North',
        8068: 'Concarneau',
        8078: 'St. Michael (Bahnhof)',
        8088: 'Narbonne',
        8098: 'Saumur Nord',
        8108: 'Saint-Nazaire',
        8118: 'Angoulême',
        8128: 'Guéret',
        8138: 'Pinggau',
        8148: 'Montluçon',
        8158: 'Roanne',
        8168: 'Sattledt',
        8178: 'La Spezia',
        8188: 'Massa',
        8198: 'Viareggio',
        8208: 'Lucca',
        8218: 'Milano (Sesto S.G.)',
        8228: 'Avignon',
        8238: 'Gothenburg',
        8248: 'Vitebsk',
        8258: 'St. Petersburg',
        8268: 'Smolensk',
        8278: 'Pskov',
        8288: 'Orsha',
        8298: 'Moscow',
        8308: 'Minsk',
        8318: 'Saint-Brieuc',
        8328: 'Caserta',
        8338: 'Salerno',
        8348: 'Drachten',
        8358: 'Saint-Malo',
        8368: 'FOCA',
        8378: 'Cafo',
        8388: 'Ouistreham',
        8398: 'Fribourg (BCF Arena)',
        8408: 'Fürth',
        8418: 'Lussac-Les-Châteaux',
        8428: "Paris Porte d'Orléans",
        8438: 'Balingen',
        8448: 'Albstadt-Ebingen',
        8458: 'Sigmaringen',
        8468: 'Mengen',
        8478: 'Bad Saulgau',
        8488: 'Agde',
        8498: 'Cap d’Agde',
        8508: 'Schwerte',
        8528: 'Dinard',
        8538: 'Wetzlar',
        8548: 'Luga',
        8558: 'Brest',
        8568: 'Mont Saint-Michel',
        8578: 'Laupheim',
        8588: 'Biberach a.d. Riß',
        8598: 'Bad Waldsee',
        8608: 'Meckenbeuren',
        8618: 'Mühldorf',
        8628: 'Bergen op Zoom',
        8638: 'Venlo Station',
        8648: 'Parthenay',
        8658: 'Barcelona',
        8668: 'Noyelles-Godault',
        8678: 'Dordrecht',
        8688: 'Morlaix',
        8698: 'Odense',
        8708: 'Guingamp',
        8718: 'Montargis Ouest',
        8728: 'Agen',
        8738: "Saint-Chély d'Apcher Nord",
        8748: 'Mendig',
        8758: 'Itzehoe',
        8778: 'Kampen',
        8788: 'List',
        8798: 'Vogelkoje',
        8808: 'Wenningstedt-Braderup',
        8818: 'Westerland',
        8828: 'Remscheid',
        8838: 'Elmshorn',
        8848: 'Tönning',
        8858: 'Tating',
        8868: 'Garding',
        8878: 'St. Peter-Ording, station',
        8888: 'St. Peter-Ording, Beach',
        8898: 'Altlüdersdorf',
        8908: 'St. Peter-Ording, Marktplatz',
        8918: 'Teschendorf',
        8928: 'Heide',
        8938: 'Nassenheide',
        8948: 'Niebüll',
        8958: 'Dannenwalde',
        8968: 'Oslo',
        8978: 'Bernburg',
        8988: 'Köthen',
        8998: 'Lunéville',
        9008: 'Saint-Dié-des-Vosges',
        9018: 'Halmstad',
        9028: 'Helsingborg',
        9038: 'Jönköping',
        9048: 'Linköping',
        9058: 'Tarbes',
        9068: 'Emsdetten',
        9078: 'Kandel',
        9088: 'Greven',
        9098: 'Neustadt (Weinstraße)',
        9108: 'Dessau',
        9118: 'Füssen, Kemptener Str.',
        9128: 'Szczecin Warzymice',
        9138: 'Orléans "Libération"',
        9148: 'Digoin',
        9158: 'Villefranche-sur-Saône',
        9168: 'Manfredonia',
        9178: 'Forlì',
        9188: 'Châtellerault',
        9198: 'Lloret de Mar',
        9208: 'Blanes',
        9218: 'Girona',
        9228: 'Niort',
        9238: 'Bastogne',
        9248: 'Weeze train station',
        9258: 'Liezen',
        9268: 'Schladming',
        9278: 'Radstadt',
        9288: 'Barletta',
        9298: 'San Severo',
        9308: 'Cerignola',
        9318: 'Herzogenrath',
        9328: 'Michalovce',
        9338: 'Sobrance',
        9348: 'Užhorod',
        9358: 'Mukačevo',
        9368: 'Zlín bus station',
        9378: 'Zlín Louky',
        9388: 'Zlín Malenovice',
        9398: 'Zlín Malenovice, ZPS',
        9408: 'Łódź Kaliska',
        9418: 'Poznań',
        9427: 'Toruń',
        9437: 'Warszawa Młociny',
        9438: 'Warszawa Chopina',
        9448: 'Warszawa Wilanowska',
        9458: 'Zakopane',
        9468: 'Rzeszów Podmiejski',
        9478: 'San Benedetto (P. Nardone)',
        9488: 'San Benedetto (P. C. Battisti)',
        9498: 'Civitanova Marche',
        9508: 'Westerland Sylt Shuttle',
        9518: 'Niebüll Sylt Shuttle',
        9528: 'Cesena -P. d. Accoglienza',
        9548: 'Bourg-de-Péage',
        9558: 'Esens ZOB',
        9568: 'Potenza',
        9578: 'Matera',
        9588: 'Perugia',
        9598: "L'Aquila",
        9608: 'Teramo',
        9618: 'Pesaro',
        9628: 'Bologna Casalecchio',
        9638: 'Priština',
        9648: 'Prizren',
        9658: 'Étaples-Le Touquet',
        9668: 'Bled',
        9678: 'Villach',
        9688: 'Funtana',
        9698: 'Koper',
        9708: 'Medulin',
        9718: 'Nova Gradiška',
        9728: 'Pirovac (Jadranska magistrala)',
        9738: 'Poreč',
        9748: 'Portorož',
        9758: 'Postojna',
        9768: 'Pula',
        9778: 'Rovinj',
        9788: 'Slavonski Brod',
        9798: 'Tar',
        9808: 'Umag',
        9818: 'Vodice',
        9828: 'Vrsar',
        9838: 'Vinkovci',
        9848: 'Županja',
        9858: 'Kutina',
        9868: 'Imotski',
        9878: 'Gračac',
        9888: 'Knin',
        9898: 'Korenica',
        9908: 'Makarska',
        9918: 'Sinj',
        9928: 'Slunj',
        9938: 'Trilj',
        9948: 'Udbina',
        9958: 'Čitluk',
        9968: 'Grude',
        9978: 'Ljubuški',
        9988: 'Međugorje',
        9998: 'Posušje',
        10008: 'Široki Brijeg',
        10018: 'Tomislavgrad',
        10028: 'Mostar',
        10048: 'Kamen',
        10058: 'Lüdenscheid',
        10078: 'Hagen (Kückelhausen)',
        10088: 'Parc Asterix',
        10098: 'Liberec',
        10108: 'Neuenburg am Rhein Station',
        10118: 'Warszawa West',
        10128: 'Milan (San Donato)',
        10138: 'Udine (autostazione)',
        10148: 'Serravalle (Outlet)',
        10158: 'Menton',
        10168: 'Bergamo (BGY airport)',
        10178: 'Arezzo',
        10188: 'Venice (Airport VCE)',
        10198: 'Monopoli',
        10208: 'Polignano',
        10218: 'Senigallia',
        10228: 'Taranto',
        10238: 'Altamura',
        10248: 'Andria',
        10258: 'Cecina',
        10268: 'Palermo (Sicily)',
        10278: 'Follonica',
        10288: 'Porto Torres (Sardinia)',
        10298: 'Cologne central station (Public Transport via Leverkusen)',
        10308: 'Sisteron',
        10318: 'Orange (Arc de Triomphe)',
        10328: 'Peschiera del Garda',
        10338: 'Gardaland',
        10348: 'Pompei',
        10358: 'Savona',
        10368: "Porto d'Ascoli",
        10378: 'Aosta',
        10388: 'Courmayeur',
        10398: 'Mantova',
        10408: 'Norrköping',
        10418: 'Lund',
        10428: 'Sarpsborg',
        10438: 'Torino (Lingotto)',
        10448: 'Dole',
        10458: 'Charleville-Mézières',
        10468: 'Sedan',
        10478: 'Bapaume',
        10488: 'Roye',
        10498: 'Montélimar',
        10508: 'Verrès',
        10518: 'Terni',
        10528: 'Vodnjan',
        10538: 'Bale',
        10548: 'Buje',
        10558: 'Brno Zvonařka',
        10568: 'Čapljina',
        10578: 'Foligno',
        10588: 'Cesena (Piaz. Karl Marx)',
        10598: 'Latina',
        10608: 'Porto Garibaldi',
        10618: 'Frosinone',
        10628: 'Benkovac',
        10638: 'Biograd bus station',
        10648: 'Dubrovnik',
        10658: 'Gospić',
        10668: 'Jastrebarsko',
        10678: 'Metković',
        10688: 'Pakoštane',
        10698: 'Samobor',
        10708: 'Saint-Martin-de-Ré',
        10718: 'Sablanceaux',
        10728: 'Katowice Andrzeja',
        10738: 'Polača',
        10748: 'Stankovci',
        10758: 'Warszawa Kijowska',
        10768: 'Warszawa Centrum',
        10778: 'Częstochowa (aleja Wojska Polskiego)',
        10788: 'Lwiw train station',
        10798: 'Lwiw bus station',
        10808: 'Rzeszów main station',
        10818: 'Parndorf',
        10828: 'Bosiljevo',
        10838: 'Posedarje',
        10848: 'Poličnik',
        10858: 'Bibinje',
        10868: 'Sukošan',
        10878: 'Sveti Filip i Jakov',
        10888: 'Maslenica',
        10898: 'Rovanjska',
        10908: 'Modrič',
        10918: 'Seline',
        10928: 'Warszawa East',
        10938: 'Starigrad',
        10948: 'Malinska',
        10958: 'Krk',
        10968: 'Punat',
        10978: 'Baška',
        10988: 'Bršadin',
        10998: 'Gradište',
        11008: 'Tribunj',
        11018: 'Murter',
        11028: 'Canterbury',
        11038: 'Novska',
        11048: 'Nuštar',
        11058: 'Leer Emspark',
        11068: 'Montauban',
        11078: 'Tisno',
        11088: 'Gornja Vrba',
        11098: 'Opuzen',
        11108: 'Klek',
        11118: 'Nagykanizsa',
        11128: 'Spoleto',
        11138: 'Rochefort',
        11148: 'Vukovar',
        11158: 'Osijek',
        11168: 'Valpovo',
        11178: 'Donji Miholjac',
        11188: 'Slatina (HR)',
        11198: 'Virovitica',
        11208: 'Pitomača',
        11218: 'Đurđevac',
        11228: 'Koprivnica',
        11238: 'Varaždin',
        11248: 'Ptuj',
        11258: 'Rogoznica',
        11268: 'Primošten (Zagrebačka ulica)',
        11288: 'Plitvička Jezera',
        11298: 'Skradinsko Polje',
        11308: 'Dugopolje',
        11318: 'Skradin',
        11328: 'Krnjak',
        11338: 'Veljun',
        11348: 'Rakovica',
        11358: 'Selište',
        11368: 'Čatrnja',
        11378: 'Grabovac',
        11388: 'Aprilia',
        11397: 'Assisi (Piaz. San Pietro)',
        11407: 'Perugia (P. Partigiani)',
        11417: 'Hasselt',
        11427: 'Rosenheim',
        11437: 'Salzburg south',
        11447: 'Valkenswaard',
        11457: 'Arlon',
        11458: 'Pistoia',
        11468: 'Avellino',
        11478: 'Benevento',
        11488: 'Leeuwarden',
        11498: 'Harlingen Haven',
        11508: 'Genoa (P. d. Vittoria)',
        11518: 'Feldkirchen',
        11528: 'Ghent (Akkerhage)',
        11538: 'Antwerp (Berchem)',
        11548: 'Cologne South, Airport',
        11558: 'Paris (Quai de Bercy)',
        11568: 'Brive-la-Gaillarde (West)',
        11578: 'Avignon (TGV-Station)',
        11588: 'Le Mans (Gare du Mans)',
        11598: 'Châtellerault Nord (Campanile Hotel)',
        11608: 'Sarzana',
        11618: 'Dover (Ferry)',
        11628: 'Marseille St. Charles',
        11638: 'Ferrara (Uscita A13)',
        11658: 'Mestre (fermata ATVO)',
        11668: 'Rovigo (Uscita A13 Rovigo Nord)',
        11678: 'Venice (Airport-ATVO)',
        11688: 'Treviso (Centro commerciale Emisfero)',
        11698: 'Longarone (stazione FS)',
        11708: 'Tai Di Cadore (Piazzale Dolomiti)',
        11718: 'Borca di Cadore',
        11728: 'San Vito di Cadore',
        11738: "Cortina d'Ampezzo",
        11748: 'Melfi',
        11758: 'Innsbruck Südbahnstraße',
        11768: 'Brussels Airport',
        11778: 'Grottaminarda',
        11788: 'Poggibonsi',
        11798: 'Orvieto',
        11808: 'Padua (Viale della Pace)',
        11818: 'Częstochowa (bus station)',
        11828: 'Charolles-Est',
        11838: 'Mantova',
        11848: 'Millau',
        11858: 'Sveta Nedelja',
        11878: 'Ostrava hl.n.',
        11888: 'Weeze Airport',
        11898: 'Linz Industriezeile',
        11908: 'Grenoble (Condillac-Universités)',
        11918: 'Brno Hotel Grand',
        11928: 'Schiphol Airport',
        11938: 'Ludbreg',
        11948: 'Čakovec',
        11958: 'Sittensen',
        11968: 'Lesce-Bled',
        11978: 'Gravina in Puglia',
        11988: 'Lavello',
        11998: 'Telese',
        12008: 'Super-Besse',
        12018: 'Schnaittach',
        12028: "Alpe d'Huez",
        12038: 'Les Deux Alpes',
        12048: 'Northeim',
        12058: 'Pirovac (Ulica kralja Krešimira IV)',
        12068: 'Luxembourg central (Parking)',
        12078: 'Orte',
        12088: 'Belluno',
        12098: 'Primošten (Dalmatinska ulica)',
        12107: 'Lunel',
        12108: 'Cassino',
        12128: 'Obernai',
        12138: 'Malpensa Terminal 1',
        12148: 'Malpensa Terminal 2',
        12158: 'Wilhelmshaven barracks',
        12168: 'Jever barracks',
        12178: 'Wittmund barracks',
        12188: 'Vechta',
        12198: 'Willingen (Upland)',
        12208: 'Chieti',
        12218: 'Saint-Lary',
        12228: 'Landry',
        12238: 'Oldenburg i.H.',
        12248: 'Emstek Autohof',
        12258: 'Freilassing',
        12268: 'Beelen',
        12278: 'Heiligenhafen',
        12288: 'Baden-Baden',
        12298: 'Achim',
        12308: 'Viernheim',
        12318: 'Herzebrock',
        12328: 'Valenciennes',
        12338: 'Vasto',
        12348: 'Arles',
        12358: 'Giulianova',
        12368: 'Nola',
        12378: 'Termoli',
        12388: 'Battiipaglia',
        12398: 'Rimini (Piazzale Gondar)',
        12408: 'Agropoli',
        12418: 'Rovigo (Piazza Riconoscenza)',
        12428: 'Eboli',
        12438: 'paestum',
        12448: 'Avezzano',
        12458: 'Westerstede',
        12468: 'Berlin TXL, E',
        12478: 'Bollène',
        12488: 'Pesaro (Piazza Stefanini)',
        12498: 'Pont Saint Martin',
        12508: 'Saint Vincent',
        12518: 'Saint Pierre',
        12528: 'La Salle',
        12538: 'Nus',
        12548: 'Morgex',
        12558: 'Prè Saint Didier',
        12568: 'Arvier',
        12578: 'Chatillon',
        12588: 'Sarre',
        12598: 'Périgueux (Créavallée Nord)',
        12608: 'Rovereto',
        12618: 'Tulle',
        12628: 'ussel',
        12638: 'Ústí nad Labem',
        12648: 'Firenze (Via Santa Caterina da Siena)',
        12658: "Città Sant'Angelo",
        12668: 'Bilbao (Termibus station)',
        12678: 'Kraków Airport',
        12688: 'Verviers',
        12698: 'penia di canazei',
        12708: 'Alba di Canazei',
        12718: 'Canazei',
        12728: 'Campitello',
        12738: 'Mazzin',
        12748: 'Pera di Fassa',
        12758: 'Pozza di Fassa',
        12768: 'San Giovanni',
        12778: 'Vigo di Fassa',
        12788: 'Soraga',
        12798: 'Wolpertshausen',
        12808: 'Moena',
        12818: 'Orly Sud',
        12828: 'Predazzo',
        12838: 'Ziano',
        12848: 'Panchià',
        12858: 'Tesero',
        12868: 'Cavalese',
        12878: 'Castello di Fiemme',
        12888: 'Veenendaal De Klomp',
        12898: 'Heerlen',
        12908: 'Wegpunkt Kornwestheim 1',
        12918: 'Wegpunkt Kornwestheim 2',
        12928: 'Luzern',
        12938: 'Passo del Tonale',
        12948: 'Vermiglio',
        12958: 'Fucine',
        12968: 'Pellizzano',
        12978: 'Mezzana / Marilleva',
        12988: 'Mestriago',
        12998: 'DImaro',
        13008: 'Pinzolo',
        13018: 'Pinzolo',
        13028: 'Carisolo',
        13038: "Sant'Antonio Mavignola",
        13048: 'Madonna di Campiglio',
        13058: 'Campo Carlo Magno',
        13068: 'Folgarida Belvedere',
        13078: 'Folgarida',
        13088: 'Monclassico',
        13098: 'Malè',
        13108: 'Cles',
        13118: 'Montabaur',
        13128: 'Mezzana',
        13138: 'Prague International Airport',
        13148: 'Arcachon',
        13158: 'BDX-Pessac',
        13168: 'Toulouse (Airport)',
        13178: 'Feldkirch',
        13188: 'Bludenz',
        13198: 'Zurich Airport',
        13208: 'Ettal',
        13218: 'Linderhof',
        13228: 'Oberammergau',
        13238: 'Steingaden',
        13248: 'Trauchgau',
        13258: 'Halblech',
        13268: 'Buching',
        13278: 'Schwangau (Neuschwanstein Castle)',
        13288: 'Füssen Pulverturm',
        13298: 'Heviz',
        13308: 'Keszthely',
        13318: 'Siofok',
        13328: 'Berlin Wannsee',
        13338: 'Schwäbisch Gmünd',
        13348: 'Göppingen',
        13358: 'Gryfów Śląski',
        13368: 'Jelenia Góra',
        13378: 'Bolków',
        13388: 'Moers',
        13398: 'Iserlohn',
        13408: 'Sangerhausen',
        13417: 'Naples (Molo Immacolatella Vecchia)',
        13427: 'Rheinböllen',
        13437: 'Alzey',
        13447: 'Löbau',
        13457: 'Zittau',
        13467: 'Mlada Boleslav',
        13477: 'Aabenraa',
        13487: 'Aalborg',
        13497: 'Aarhus Havn',
        13498: 'Mülheim/Ruhr',
        13508: 'Esbjerg',
        13518: 'Fredericia',
        13528: 'Helsingør',
        13538: 'Helsingør St.',
        13548: 'Horsens',
        13558: 'Middelfart',
        13568: 'Odense South',
        13578: 'Randers',
        13588: 'Roskilde',
        13598: 'Silkeborg',
        13608: 'Åre',
        13618: 'Årjäng',
        13628: 'Arlanda Airport',
        13638: 'Borås',
        13648: 'Gävle',
        13658: 'Grums',
        13668: 'Härnösand',
        13678: 'Järpen',
        13688: 'Karlskoga',
        13698: 'Karlstad',
        13708: 'Knöstad',
        13718: 'Kristinehamn',
        13728: 'Nordmaling',
        13738: 'Örebro',
        13748: 'Örnsköldsvik',
        13758: 'Östersund',
        13768: 'Söderhamn',
        13778: 'Södertälje',
        13788: 'Sundsvall',
        13798: 'Töcksfors',
        13808: 'Ullånger',
        13818: 'Umeå',
        13828: 'Uppsala',
        13838: 'Västerås',
        13848: 'Ås',
        13858: 'Moss',
        13868: 'Stjørdal',
        13878: 'Trondheim S',
        13888: 'Trondheim Airport',
        13898: 'Jena Göschwitz',
        13908: 'Viborg',
        13918: 'Vejen',
        13928: 'Skive',
        13938: 'Lippstadt',
        13948: 'Plauen Mitte',
        13958: 'Zvolen',
        13968: 'Banská Bystrica',
        13978: 'Donovaly',
        13988: 'Ružomberok bus station',
        13998: 'Dolný Kubín',
        14008: 'Myślenice',
        14028: 'Wolfsburg Autostadt',
        14038: 'Issoire',
        14048: 'Allgäu Skyline Park',
        14058: 'Legoland Germany',
        14068: 'Košice',
        14078: 'Bad Salzuflen',
        14088: 'Prešov',
        14098: 'Poprad',
        14108: 'Liptovský Mikuláš',
        14118: 'Žilina',
        14128: 'Wittlich',
        14138: 'Polch',
        14198: 'Giurgiu',
        14288: 'Budapest Airport',
        14298: 'Debrecen',
        14308: 'Kecskemét',
        14318: 'Miskolc',
        14338: 'Timișoara Autotim',
        14348: 'Saarlouis Hbf',
        14358: 'Łódź Fabryczna',
        14368: 'Ostend',
        14378: 'Rottenburg a.N.',
        14388: 'Schaffhausen',
        14398: 'Laufen-Uhwiesen',
        14408: 'Como',
        14418: 'Raubling',
        14428: 'Bernau a. C.',
        14438: 'Berchtesgaden',
        14448: 'Schönau am Königssee',
        14468: 'Neustadt/Donau',
        14478: 'Gothenburg Landvetter Airport',
        14488: 'Saint-Julien-en-Genevois',
        14498: 'BELLEGARDE PÉAGE',
        14508: 'Alba Iulia',
        14518: 'Miercurea Ciuc',
        14548: 'Aiud',
        14558: 'Turda',
        14568: 'Cluj',
        14578: 'Huedin',
        14588: 'Oradea',
        14618: 'Szeged',
        14628: 'Târgu Mureş',
        14638: 'Baia Mare',
        14648: 'Satu Mare',
        14658: 'Carei',
        14668: 'Sighișoara',
        14678: 'Odorheiu Secuiesc',
        14698: 'Aachen Hüls',
        14708: 'Wertheim Village',
        14718: 'Zgorzelec',
    },
    userId: 196208,
    userName: 'John Doe',
    delayCategories: {
        'on-time': {
            min: 0,
            max: 60,
        },
        '1-15 min': {
            min: 60,
            max: 900,
        },
        '15-30 min': {
            min: 900,
            max: 1800,
        },
        '30-45 min': {
            min: 1800,
            max: 2700,
        },
        '45-60 min': {
            min: 2700,
            max: 3600,
        },
        '60-90 min': {
            min: 3600,
            max: 5400,
        },
        '90-120 min': {
            min: 5400,
            max: 7200,
        },
        '120-XXX min': {
            min: 7200,
            max: null,
        },
    },
    maxDelayCategory: '120-XXX min',
};

export default autocontrolConst;
