import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useApi } from '../../api';
import ConfigProviderContext from './ConfigProviderContext';
import { TranslateContext } from '../../system/Translate';

export default function ConfigProvider({ children }) {
    const [config, request] = useApi('get', '/options');
    const { translate } = useContext(TranslateContext);

    useEffect(() => {
        request();
    }, [request]);
    return (
        <ConfigProviderContext.Provider value={config.data}>
            {config.data !== null ? children : translate('loading')}
        </ConfigProviderContext.Provider>
    );
}

ConfigProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
