import { useEffect, useState, useContext } from 'react';
import * as FILTERS from '../filtersList';
import { TranslateContext } from '../../../system/Translate';

const generateMap = (t) => {
    return {
        [FILTERS.LINE_ID]: t('line'),
        [FILTERS.TYPE]: t('type'),
        [FILTERS.RIDE_FROM_ID]: t('from'),
        [FILTERS.RIDE_TO_ID]: t('to'),
        [FILTERS.CONCESSION_OWNER_ID]: t('concession_owner'),
        [FILTERS.EXCLUDE_CONCESSION_OWNER_ID]: t('exclude_concession_owner'),
        [FILTERS.ACKNOWLEDGED]: t('status'),
        [FILTERS.BUSINESS_REGION]: t('businessRegion'),
        [FILTERS.EXCLUDE_BUSINESS_REGION]: t('excludeBusinessRegion'),
    };
}

/**
 * Hook that provides mapping filters name with labels
 * @function useFiltersMap
 * @returns {object} map of filters {[name]: label} list
 */
export default function useFiltersMap() {
    const { translate, language } = useContext(TranslateContext);

    const [fm, setFm] = useState(generateMap(translate));

    useEffect(() => {
        setFm(generateMap(translate));
    }, [setFm, language]);

    return fm;
}
