class DebounceCache {
    cache = [];
    timer = null;
    callback = () => {};
    setCallback = (cb) => {
        this.callback = cb;
    };
    addCache = (data) => {
        this.cache.push(data);
        if (this.timer === null) {
            this.timer = setTimeout(() => {
                this.timer = null;
                this.callback(this.cache);
                this.cache = [];
            }, 2000);
        }
    };
}

export default new DebounceCache();
