import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { FiltersProviderContext } from '../FiltersProvider';
import { Tag, Button, Grid, GridCol } from '@flixbus/honeycomb-react';
import { Icon, IconClose } from '@flixbus/honeycomb-icons-react';
import { TranslateContext } from '../../system/Translate';

const FiltersCloudMemoized = (props) => {
    const { options, reset } = props;
    const { translate } = useContext(TranslateContext);
    const fpc = useContext(FiltersProviderContext);
    const { filters, deleteAction } = fpc;

    const tags = Object.entries(filters).reduce((acc, item) => {
        const result = [...acc];
        const optionsEntry = options[item[0]];
        let option;
        if (!optionsEntry) {
            option = {
                type: item[0],
                title: item[1],
                subtitle: item[0],
                value: item[1],
            };
        } else {
            option = optionsEntry.filter((opt) => {
                if (Array.isArray(item[1])) {
                    return item[1].includes(opt.value);
                }
                return opt.value === item[1];
            });
        }
        return result.concat(option);
    }, []);

    return (
        <Grid align="top">
            <GridCol size={12}>
                {tags.map((tag) => (
                    <Tag
                        type="button"
                        Elem="button"
                        display="outlined"
                        style={{ marginRight: '10px', marginBottom: '10px' }}
                        key={`${tag.value}-${tag.type}`}
                        onClick={() => {
                            deleteAction(tag.type, tag.value);
                        }}
                    >
                        <strong>{translate(tag?.type)}:</strong> {tag?.title}
                        &nbsp;
                        <Icon InlineIcon={IconClose} />
                    </Tag>
                ))}
                {tags.length > 0 && (
                    <Button
                        appearance="primary"
                        link
                        style={{ marginBottom: '10px' }}
                        onClick={reset}
                        aria-label={translate('reset')}
                    >
                        {translate('reset_all')}
                    </Button>
                )}
            </GridCol>
        </Grid>
    );
};

export const FiltersCloud = React.memo(FiltersCloudMemoized, () => true);

FiltersCloudMemoized.propTypes = {
    tags: PropTypes.array,
};
FiltersCloudMemoized.defaultProps = {
    tags: [],
};
