import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import {
    Connection,
    ConnectionStop,
    Button,
    Spinner,
    Tooltip,
    ConnectionTime,
    Time
} from '@flixbus/honeycomb-react';
import {
    IconCheckmarkStrongSolid,
    Icon,
    IconReset,
} from '@flixbus/honeycomb-icons-react';
import NotificationInfo from './NotificationInfo';
import RideInfoTag from './RideInfoTag';
import NotificationStatuses from './NotificationsStatuses';
import useActions from './useActions';
import { TranslateContext } from '../../system/Translate';

import './ListItem.scss';

const TIME_FORMAT = 'HH:mm';
const TIME_AND_DATE_FORMAT = 'HH:mm—DD.MM.YYYY';
const DEFAULT_TZ = 'Europe/Berlin';

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

const ListItem = React.memo((props) => {
    const {
        notification,
        stationNameById,
        rideViewUrl,
        busViewUrl,
        orderViewUrl,
        isLocal,
    } = props;
    const {
        ride,
        createdAt,
        line,
        segment,
        acknowledged,
        acknowledgement,
        id,
    } = notification;
    const autoAcknowledged = acknowledgement?.autoAcknowledged;

    const {
        rideFromName,
        rideToName,
        rideDeparture,
        rideArrival,
        rideFromTimezone,
        rideToTimezone,
    } = ride;
    const { lineColor } = line;

    const {
        segmentFromName,
        segmentToName,
        segmentToTimezone,
        segmentFromTimezone,
        segmentArrival,
        segmentDeparture,
    } = segment;

    const [actionInProcess, actions] = useActions(id);

    const showUTC = (time, tz, skip = false) => {
        const date = !skip
            ? isLocal === 'local'
                ? dayjs(time)
                : dayjs(time).tz(tz)
            : dayjs(time).tz(DEFAULT_TZ);

        let res = date.format('ZZ').substring(0, date.format('ZZ').length - 2);
        res = res.charAt(1) === '0' ? res.replace('0', '') : res;

        return ` (UTC ${res})`;
    };

    const rideDepartureDate =
        isLocal === 'local'
            ? dayjs(rideDeparture)
            : dayjs(rideDeparture).tz(rideFromTimezone || segmentFromTimezone);
    const rideArrivalDate =
        isLocal === 'local'
            ? dayjs(rideArrival)
            : dayjs(rideArrival).tz(rideToTimezone || segmentToTimezone);

    const segmentDepartureDate =
        isLocal === 'local'
            ? dayjs(segmentDeparture)
            : dayjs(segmentDeparture).tz(segmentFromTimezone);
    const segmentArrivalDate =
        isLocal === 'local'
            ? dayjs(segmentArrival)
            : dayjs(segmentArrival).tz(segmentToTimezone);

    const rideFromUTC =
        (rideFromTimezone || segmentFromTimezone) &&
        showUTC(rideDeparture, rideFromTimezone || segmentFromTimezone);
    const rideToUTC =
        (rideToTimezone || segmentToTimezone) &&
        showUTC(rideArrival, rideToTimezone || segmentToTimezone);

    const rideDepartureTime = rideDepartureDate.format(TIME_AND_DATE_FORMAT);
    const rideArrivalTime = rideArrivalDate.format(TIME_AND_DATE_FORMAT);

    const segmentFromUTC =
        segmentFromTimezone && showUTC(segmentDeparture, segmentFromTimezone);
    const segmentToUTC =
        segmentToTimezone && showUTC(segmentArrival, segmentToTimezone);

    const segmentDepartureTime = segmentDepartureDate.format(TIME_FORMAT);
    const segmentArrivalTime = segmentArrivalDate.format(TIME_FORMAT);

    let ageElement;

    if (!dayjs(createdAt).isSame(new Date())) {
        ageElement = (
            <small className="ago">{dayjs(createdAt).fromNow()}</small>
        );
    }

    const { translate } = useContext(TranslateContext);

    const handleActionButtonClick = (event, acknowledgment) => {
        event.preventDefault();
        acknowledgment ? actions('acknowledge') : actions('acknowledge-revoke');
        window.open(rideViewUrl.replace('{rideId}', ride?.rideId));
    }

    return (
        <div className="result-item" data-id={id}>
            <div className="results-col results-col--line">
                <RideInfoTag lineColor={lineColor} type="colored">
                    <a
                        href={rideViewUrl.replace('{rideId}', ride?.rideId)}
                        target="_blank"
                        title={translate('line')}
                        className="ride-info-tag__line-indicator"
                        rel="noreferrer"
                    >
                        <IconCheckmarkStrongSolid className="ride-info-tag__icon" style={{ fill: `#${lineColor}` }} />
                        {line?.lineCode}
                    </a>
                </RideInfoTag>
            </div>
            <div className="results-col results-col--dep">
                <Connection>
                    <ConnectionStop
                        extraClasses="results-col__stop"
                        station={rideFromName}
                        time={<ConnectionTime time={(<Time>{rideDepartureTime}<span className="result-item__utc">{rideFromUTC}</span></Time>)} />}
                    />
                    <ConnectionStop
                        extraClasses="results-col__stop"
                        station={segmentFromName}
                        time={<ConnectionTime time={<Time>{segmentDepartureTime} <span className="result-item__utc">{segmentFromUTC}</span></Time>} />}
                    />
                    <ConnectionStop
                        extraClasses="results-col__stop"
                        station={segmentToName}
                        time={<ConnectionTime time={<Time>{segmentArrivalTime} <span className="result-item__utc">{segmentToUTC}</span></Time>} />}
                    />
                    <ConnectionStop
                        extraClasses="results-col__stop"
                        station={rideToName}
                        time={<ConnectionTime time={(<Time>{rideArrivalTime}<span className="result-item__utc">{rideToUTC}</span></Time>)} />}
                    />
                </Connection>
            </div>
            <div className="results-col results-col--created">
                <span className="time">
                    {dayjs(createdAt).format('HH:mm')}
                    <span className="utc">{showUTC(createdAt, DEFAULT_TZ, true)}</span>
                </span>
                {ageElement}
            </div>
            <div className="results-col results-col--type">
                <NotificationInfo
                    notification={notification}
                    stationNameById={stationNameById}
                    rideViewUrl={rideViewUrl}
                    busViewUrl={busViewUrl}
                    orderViewUrl={orderViewUrl}
                />
            </div>
            <div className="results-col results-col--status">
                <NotificationStatuses notification={notification} />
            </div>
            <div className="results-col results-col--actions">
                {actionInProcess ? (
                    <Spinner size="md" />
                ) : !acknowledged && !autoAcknowledged ? (
                    <Tooltip
                        id="tooltip-acknowledge"
                        size="content-fit"
                        openOnHover
                        position="bottom"
                        content={translate('acknowledgment')}
                    >
                        <Button
                            appearance="secondary"
                            display="square"
                            onClick={(e) => handleActionButtonClick(e, true)}
                        >
                            <Icon InlineIcon={IconCheckmarkStrongSolid} />
                        </Button>
                    </Tooltip>
                ) : (
                    <Tooltip
                        id="tooltip-revoke"
                        size="content-fit"
                        openOnHover
                        position="bottom"
                        content={translate('revoke_acknowledgment')}
                    >
                        <Button appearance="danger" display="square" onClick={(e) => handleActionButtonClick(e, false)}>
                            <Icon InlineIcon={IconReset} />
                        </Button>
                    </Tooltip>
                )}
            </div>
        </div>
    );
});

ListItem.propTypes = {
    notification: PropTypes.object,
    rideViewUrl: PropTypes.string,
    busViewUrl: PropTypes.string,
};
ListItem.defaultProps = {};

export default ListItem;
