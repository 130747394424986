import { useCallback } from 'react';

export default function useActionsCreator(dispatcher) {
    const addAction = useCallback(
        (key, list = false) =>
            (value) => {
                dispatcher({ type: 'add', payload: { key, value, list } });
            },
        [dispatcher]
    );

    const deleteAction = useCallback(
        (key, value) => {
            dispatcher({
                type: 'delete',
                payload: { key, value },
            });
        },
        [dispatcher]
    );

    const resetAction = useCallback(
        () => () => {
            dispatcher({ type: 'reset' });
        },
        [dispatcher]
    );

    return {
        addAction,
        deleteAction,
        resetAction,
    };
}
