import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, AutocompleteInput, AutocompleteOptions } from '@flixbus/honeycomb-react';
import { Icon } from "@flixbus/honeycomb-icons-react";
import { useFilter } from '../../hooks';

const ListPickerMemoized = (props) => {
    const {
        id,
        onChange,
        options,
        label,
        placeholder,
        shouldResetValue,
        InlineIcon,
        value: innerVal,
        distributeBy,
        renderOption,
        extraClasses,
        ariaLabel,
        withoutInputLabel,
        ...rest
    } = props;
    const [filteredOptions, filter] = useFilter(options, 'title');
    const [value, setValue] = useState();
    const [results, setResults] = useState(null);
    const [query, setQuery] = useState('');
    const [multiOptions, setMultiOptions] = useState();
    const { multiselect } = rest;

    const mapInnerValueToValue = useCallback(() => {
        if (Array.isArray(innerVal) && multiselect && options.length > 0) {
            const mlpOpts = innerVal.map((val) =>
                options.reduce(
                    (acc, opt) => (opt.value === val ? opt : acc),
                    {}
                )
            );
            setMultiOptions(mlpOpts);
        } else {
            const searchValue = options.reduce((acc, item) => {
                if (item.value === innerVal) {
                    return item.title;
                }
                return acc;
            }, '');
            setValue(searchValue);
        }
    }, [options, setValue, innerVal, setMultiOptions, multiselect]);

    const distributeResults = useCallback(() => {
        if (typeof distributeBy === 'string') {
            const distributed = results.reduce((acc, option) => {
                const res = { ...acc };
                if (Array.isArray(res[option[distributeBy]])) {
                    res[option[distributeBy]].push(option);
                } else {
                    res[option[distributeBy]] = [option];
                }
                return res;
            }, {});

            return Object.entries(distributed).reduce((acc, entry) => {
                const res = [...acc];
                return res.concat(entry[1].slice(0, 5));
            }, []);
        }
        return results.slice(0, 10);
    }, [results, distributeBy]);

    // Track if a search query is empty to do not show options list
    // with initial render
    useEffect(() => {
        if (query !== '') {
            setResults(filteredOptions);
        } else {
            setResults(null);
        }
    }, [query, setResults, filteredOptions]);

    // match income value with options and set alias
    useEffect(() => {
        mapInnerValueToValue();
    }, [mapInnerValueToValue]);

    // do not show list while change options list with new response/sets
    // and update it while it is active with search query
    useEffect(() => {
        if (results !== null && filteredOptions !== results) {
            setResults(filteredOptions);
        }
    }, [options, filteredOptions, setResults, results]);

    // filter options with query and options changed
    useEffect(() => {
        filter(query);
    }, [query, options, filter]);


    return (
        <Autocomplete
            optionsSelected={multiOptions}
            value={value}
            options={results === null ? [] : distributeResults()}
            onChange={(e) => {
                // set the same value in to different state
                // to track actions differently with useEffect
                setValue(e.target.value);
                setQuery(e.target.value);
            }}
            onSelect={(item) => {
                setResults(null);
                // reset query to prevent of showing list after re-render
                setQuery('');
                if (shouldResetValue() === true) {
                    setValue('');
                } else {
                    setValue(item.title);
                }
                onChange(item.value, distributeBy ? item[distributeBy] : undefined);
            }}
            extraClasses={extraClasses}
        >
            <AutocompleteInput
                id={id}
                placeholder={placeholder}
                label={withoutInputLabel ? '' : label}
                aria-label={ariaLabel}
                loading={false}
                iconLeft={ InlineIcon && <Icon InlineIcon={InlineIcon} />}
            />
            <AutocompleteOptions
                renderOption={renderOption}
                label={label}
                optionsToDisplay={3}
                optionHasSubtitle={false}
            />
        </Autocomplete>
    );
};

export const ListPicker = React.memo(ListPickerMemoized, (prevProps, nextProps) => {
    return prevProps.label === nextProps.label;
});

ListPickerMemoized.propTypes = {
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        })
    ),
    shouldResetValue: PropTypes.func,
};
ListPickerMemoized.defaultProps = {
    onChange: () => { },
    options: [],
    shouldResetValue: () => false,
};
