import React, { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, GridCol, FormRow, SelectGroup, Select, SelectOption } from '@flixbus/honeycomb-react';
import {
    IconBus,
    IconBusesSideNo,
    IconBusSide,
    IconDocument,
    IconLabel,
} from '@flixbus/honeycomb-icons-react';
import { FiltersProviderContext } from '../FiltersProvider';
import { TranslateContext } from '../../system/Translate';
import { ListPicker, ComboStopsPicker } from '../FormElements';
import { FILTERS } from '.';
import SelectWithIcon from "./SelectWithIcon/SelectWithIcon";

const FiltersMemoized = (props) => {
    const { options, language } = props;
    const { translate } = useContext(TranslateContext);
    const fpc = useContext(FiltersProviderContext);
    const { filters, addAction, deleteAction } = fpc;
    const [ typeValue, setTypeValue ] = useState(" ");
    const [businessRegionValue, setBusinessRegionValue] = useState(" ");
    const [excludeBusinessRegionValue, setExcludeBusinessRegionValue] = useState(" ");

    const LineNumber = useMemo(
        () => (
            <ListPicker
                label={translate('line_number')}
                id={FILTERS.LINE_ID}
                InlineIcon={IconBus}
                options={options[FILTERS.LINE_ID]}
                onChange={addAction(FILTERS.LINE_ID, true)}
                value=""
                shouldResetValue={() => true}
            />
        ),
        [options, language, addAction, translate]
    );

    const Status = () => {
        const optionsList = options[FILTERS.ACKNOWLEDGED].map((option) => ({
            id: option.value,
            value: option.value,
            name: option.title,
            label: option.title,
            checked: filters?.acknowledged?.includes(option.value) || false,
        }));

        const onChange = (e) => {
            optionsList.map((item) => {
                if (item.value === e.target.value) {
                    if (item.checked) {
                        deleteAction(FILTERS.ACKNOWLEDGED, e.target.value);
                    } else {
                        addAction(FILTERS.ACKNOWLEDGED, true)(e.target.value);
                    }
                    item.checked = !item.checked;
                }

                return item;
            });
        };

        const opt = optionsList.map((o) => {
            o.onChange = onChange;
            return o;
        });

        return (
            <SelectGroup
                multi={true}
                label={translate('status')}
                options={opt}
                id={FILTERS.ACKNOWLEDGED}
                extraClasses="autocontrol__filters-status"
            />
        );
    };

    const ComboStop = useMemo(
        () => (
            <ComboStopsPicker
                ids={[FILTERS.RIDE_FROM_ID, FILTERS.RIDE_TO_ID]}
                labels={[translate('from'), translate('to')]}
                actions={[
                    addAction(FILTERS.RIDE_FROM_ID),
                    addAction(FILTERS.RIDE_TO_ID),
                ]}
                options={options[FILTERS.RIDE_FROM_ID]}
            />
        ),
        [options, language, addAction, translate]
    );

    const ConcessionOnwer = useMemo(
        () => (
            <ListPicker
                label={translate('concession_owner')}
                id={FILTERS.CONCESSION_OWNER_ID}
                options={options[FILTERS.CONCESSION_OWNER_ID]}
                onChange={addAction(FILTERS.CONCESSION_OWNER_ID, true)}
                value=""
                InlineIcon={IconDocument}
                shouldResetValue={() => true}
            />
        ),
        [options, language, addAction, translate]
    );

    const ExcludeConcessionOnwer = useMemo(
        () => (
            <ListPicker
                label={translate('exclude_concession_owner')}
                id={`not-${FILTERS.CONCESSION_OWNER_ID}`}
                options={options[`not-${FILTERS.CONCESSION_OWNER_ID}`]}
                onChange={addAction(`not-${FILTERS.CONCESSION_OWNER_ID}`, true)}
                value=""
                InlineIcon={IconDocument}
                shouldResetValue={() => true}
            />
        ),
        [options, language, addAction, translate]
    );

    const BusinessRegion = () => {
        const handleChange = (value) => {
            setBusinessRegionValue(" ");
            addAction(FILTERS.BUSINESS_REGION, true)(value)
        }

        return (
            <SelectWithIcon icon={IconBusSide}>
                <Select
                    placeholder=" "
                    value={businessRegionValue}
                    id={FILTERS.BUSINESS_REGION}
                    label={translate('businessRegion')}
                    onChange={({ target }) => handleChange(target.value)}
                >
                    {options[FILTERS.BUSINESS_REGION].map(({ title, value }) => (
                        <SelectOption key={value} value={value}>{title}</SelectOption>
                    ))}
                </Select>
            </SelectWithIcon>
        )
    }

    const ExcludeBusinessRegion = () => {
        const handleChange = (value) => {
            setExcludeBusinessRegionValue(" ");
            addAction(FILTERS.EXCLUDE_BUSINESS_REGION, true)(value)
        }

        return (
            <SelectWithIcon icon={IconBusesSideNo}>
                <Select
                    placeholder=" "
                    value={excludeBusinessRegionValue}
                    id={FILTERS.EXCLUDE_BUSINESS_REGION}
                    label={translate('excludeBusinessRegion')}
                    onChange={({ target }) => handleChange(target.value)}
                >
                    {options[FILTERS.EXCLUDE_BUSINESS_REGION].map(({ title, value }) => (
                        <SelectOption key={value} value={value}>{title}</SelectOption>
                    ))}
                </Select>
            </SelectWithIcon>
        )
    }

    const handleTypeChange = (value) => {
        setTypeValue(" ")
        addAction(FILTERS.TYPE, true)(value)
    }

    return (
        <div>
            <Grid>
                <GridCol size={4} md={5} xl={6} sm={12}>
                    <FormRow spacing='4'>{LineNumber}</FormRow>
                </GridCol>
                <GridCol size={4} md={3} xl={3} sm={6}>
                    <FormRow spacing='4'>
                        <SelectWithIcon icon={IconLabel}>
                            <Select
                                value={typeValue}
                                id={FILTERS.TYPE}
                                label={translate('notification')}
                                placeholder=" "
                                onChange={({ target }) => handleTypeChange(target.value)}
                            >
                                {options[FILTERS.TYPE].map(({ title, value }) => (
                                    <SelectOption key={value} value={value}>{title}</SelectOption>
                                ))}
                            </Select>
                        </SelectWithIcon>
                    </FormRow>
                </GridCol>
                <GridCol size={4} md={4} xl={3} sm={6}>
                    <FormRow spacing='4'>{Status()}</FormRow>
                </GridCol>
            </Grid>
            <Grid>
                <GridCol>
                    <FormRow spacing='4'>{ComboStop}</FormRow>
                </GridCol>
                <GridCol>
                    <Grid>
                        <GridCol>
                            <FormRow spacing='4'>{ConcessionOnwer}</FormRow>
                        </GridCol>
                        <GridCol>
                            <FormRow spacing='4'>{ExcludeConcessionOnwer}</FormRow>
                        </GridCol>
                    </Grid>
                </GridCol>
            </Grid>
            <Grid>
                <GridCol>
                    <FormRow spacing='4'>{BusinessRegion()}</FormRow>
                </GridCol>
                <GridCol>
                    <FormRow spacing='4'>{ExcludeBusinessRegion()}</FormRow>
                </GridCol>
            </Grid>
        </div>
    );
};

export const Filters = React.memo(FiltersMemoized, (prevProps, nextProps) => {
    return prevProps.language !== nextProps.language;
});

// export const Filters = FiltersMemoized;

FiltersMemoized.propTypes = {
    options: PropTypes.object,
    language: PropTypes.string,
};
FiltersMemoized.defaultProps = { options: {}, language: 'en' };
