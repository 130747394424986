import { useState } from 'react';
import SortProviderContext from './SortProviderContext';

export default function SortProvider({ children }) {
    const [sort, setSort] = useState('createdAt,asc');
    function sorting(rule, direction = 'asc') {
        setSort(`${rule},${direction}`);
    }
    return (
        <SortProviderContext.Provider value={{ sort, sorting }}>
            {children}
        </SortProviderContext.Provider>
    );
}
