import React, { useContext, useEffect } from 'react';
import { Spinner } from '@flixbus/honeycomb-react';
import { TranslateContext } from '../../system/Translate';
import { NotificationContext } from '../Notification';

const NOTIFS_LOADING_DELAY = 8000; // 8 sec
const NOTIFS_LOADING_MSG_TIMEOUT = 2000; // 2 sec

export default function ResultsSpinner({ loading }) {
    const notify = useContext(NotificationContext);
    const { translate } = useContext(TranslateContext);

    useEffect(() => {
        // first gentle notifications loading message
        const firstNotification = setTimeout(() => {
            notify({
                text: translate('please_wait_notifications_are_still_loading'),
                expires: NOTIFS_LOADING_DELAY / 2,
            });
        }, NOTIFS_LOADING_DELAY + NOTIFS_LOADING_MSG_TIMEOUT);

        // second gentle notifications loading message
        const secondNotification = setTimeout(() => {
            notify({
                text: translate('almost_done'),
                expires: NOTIFS_LOADING_DELAY / 2,
            });
        }, NOTIFS_LOADING_DELAY * 2 + NOTIFS_LOADING_MSG_TIMEOUT);

        if (loading) {
            notify({
                text: translate('notifications_are_loading'),
                expires: NOTIFS_LOADING_DELAY,
            });
        }

        return () => {
            clearTimeout(firstNotification);
            clearTimeout(secondNotification);
        };
    }, [loading, notify]);

    return (
        <div className="spinner-wrapper">
            <Spinner size="xl" />
        </div>
    );
}
