import React from 'react';
import { List } from "@flixbus/honeycomb-react";

export default function AlgorithmRepresentation({ algorithmString }) {
    const parseAlgorithm = (algorithm = []) => {

        const splitByBrackets = (algorithm, splitChar) => {
            let algorithmParts = [];
            let bracketCounter = 0;

            for (let i = 0; i<algorithm.length; i++) {
                if(algorithm.substring(i,i+1) === "("){
                    ++bracketCounter;
                } else if (algorithm.substring(i,i+1) === ")") {
                    --bracketCounter;
                } else if (algorithm.substring(i,i+1) === splitChar && 0 === bracketCounter) {
                    algorithmParts.push(algorithm.substring(0, i));
                    algorithm = algorithm.substring(i+1);
                    i = 0;
                }
            }

            if (algorithm.length > 0) {
                algorithmParts.push(algorithm);
            }

            return algorithmParts;
        }

        const getBracketsContent = (rawContents) => {
            return rawContents.substring(
                rawContents.indexOf("(") + 1,
                rawContents.lastIndexOf(")")
            );
        }

        return splitByBrackets(algorithm, ";").reduce((result, item) => {
            const obj = {
                representation:"",
                operation: {},
                calls:[]
            };
            const splitStatementParts = splitByBrackets(item, ".");
            obj.representation = item;
            obj.operation = {
                method: splitStatementParts[0].substring(0,splitStatementParts[0].indexOf("(")),
                parameters: getBracketsContent(splitStatementParts[0])
            }

            for (let i= 1; i < splitStatementParts.length; i++) {
                obj.calls.push({
                    method: splitStatementParts[i].substring(0,splitStatementParts[i].indexOf("(")),
                    children: parseAlgorithm(getBracketsContent(splitStatementParts[i]))
                });
            }

            result.push(obj);
            return result;
        }, []);
    }

    const renderAlgorithm = (parsedAlgorithm) => {
        return <List>
            {parsedAlgorithm.reduce((result, item) => {
                result.push(
                    <li key={item.representation} className="flix-list-wrapper__item">
                        <div className="flix-list-wrapper__content">
                            <strong>{item.operation.method}</strong>
                            (<i>{item.operation.parameters}</i>)
                        </div>
                        {item.calls.reduce((result, callDetails) => {
                            result.push(
                                <div>
                                    <strong>{callDetails.method}</strong>
                                    {renderAlgorithm(callDetails.children)}
                                </div>
                            );

                            return result;
                        }, [])}
                    </li>
                );
                return result;
            }, [])}
        </List>
    }

    const parsedAlgorithm = parseAlgorithm(algorithmString);

    return (
        <>
            {renderAlgorithm(parsedAlgorithm)}
        </>
    )
}
