import React, { useContext } from 'react';
import { TabItem, TabPanel, Tabs } from "@flixbus/honeycomb-react";
import { FiltersWrapper } from '../Filters/FiltersWrapper';
import { filterFunction } from '../../helpers';
import { sortRules, SortProviderContext } from '../SortProvider';
import { TranslateContext } from "../../system/Translate";
import { ConfigProviderContext } from '../ConfigProvider';
import { WDProviderContext } from '../WDprovider';
import { FiltersProviderContext } from '../FiltersProvider';

import Layout from '../Layout';
import Results from '../Results';
import ArchivedResults from "../Results/ArchivedResults";


export default function ResultsPage() {
    const { translate } = useContext(TranslateContext);
    const config = useContext(ConfigProviderContext);
    const { notifications, loading, loadMore, isLoadMore } = useContext(WDProviderContext);
    const { filters } = useContext(FiltersProviderContext);
    const { sort } = useContext(SortProviderContext);

    const [rule, direction] = sort.split(',');

    const stationNameById = (id) => {
        if (!config?.stops?.hasOwnProperty(id)) {
            return '';
        }
        return config.stops.stations[id];
    };

    const getNotifications = (notifications) => {
        return notifications
            .filter((notification) =>
                filterFunction(Object.keys(filters), notification, filters)
            )
            .sort(sortRules[rule](direction));
    };

    const tabs = (
        <>
            <TabItem id="live-notifications" panelId="live-notifications-panel">{translate('live')}</TabItem>
            <TabItem id="archived-notifications" panelId="archived-notifications-panel">{translate('archive')}</TabItem>
        </>
    );

    const panels = (
        <>
            <TabPanel id="live-notifications-panell" tabId="live-notifications">
                <Results
                    loadMore={loadMore}
                    isLoadMore={isLoadMore}
                    language="en"
                    stationNameById={stationNameById}
                    loading={loading}
                    notifications={getNotifications(notifications)}
                    rideViewUrl={config.rideViewUrl}
                    busViewUrl={config.busViewUrl}
                    orderViewUrl={config.orderViewUrl}
                />
            </TabPanel>
            <TabPanel id="archived-notifications-panel" tabId="archived-notifications">
                <ArchivedResults
                    stationNameById={stationNameById}
                    rideViewUrl={config.rideViewUrl}
                    busViewUrl={config.busViewUrl}
                    orderViewUrl={config.orderViewUrl}
                />
            </TabPanel>
        </>
    );

    return (
        <>
            <FiltersWrapper />
            <Layout>
                <Tabs aria-label="Notifications type selection" tabs={tabs} panels={panels} />
            </Layout>
        </>
    );
}
