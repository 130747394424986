/** list of field names for form */
export const LINE_ID = 'line.lineId';
export const TYPE = 'type'; // Notifications type
export const RIDE_FROM_ID = 'ride.rideFromId';
export const RIDE_TO_ID = 'ride.rideToId';
export const CONCESSION_OWNER_ID = 'concessionOwnerId';
export const EXCLUDE_CONCESSION_OWNER_ID = 'not-concessionOwnerId';
export const ACKNOWLEDGED = 'acknowledged';
export const BUSINESS_REGION = 'businessRegion';
export const EXCLUDE_BUSINESS_REGION = 'excludeBusinessRegion';
