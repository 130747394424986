import { useReducer, useCallback, useContext, useEffect } from 'react';

import { ConfigProviderContext } from '../../ConfigProvider';
import {
    useFiltersMap,
    useConcessionOwners,
    useLines,
    useStops,
    useBusinessRegions
} from '../hooks';

import { FILTERS } from '../../Filters';
import * as CONST from '../filtersList';
import { normalizeConfigEntries, generateOptions } from '../../../helpers';

import { ACKNOWLEDGED_STATUSES } from '../../WDprovider/filterHelper';

export default function useFiltersOptions() {
    const config = useContext(ConfigProviderContext);

    const [concessionOwners, getConcessionOwners] = useConcessionOwners();
    const [businessRegions, getBusinessRegions] = useBusinessRegions();
    const [stops, getStops] = useStops();
    const [lines, getLines] = useLines();

    const filtersMap = useFiltersMap();

    const [state, dispatch] = useReducer(reducer, {
        data: null,
        pending: false,
        error: false,
    });

    useEffect(() => {
        if (
            stops.data !== null &&
            lines.data !== null &&
            businessRegions.data !== null &&
            concessionOwners.data !== null
        ) {
            const businessRegionsOptions = generateOptions(
                normalizeConfigEntries(businessRegions),
                { title: 'title', value: 'id' },
                {
                    type: FILTERS.BUSINESS_REGION,
                    subtitle: filtersMap[CONST.BUSINESS_REGION]
                }
            );
            const excludeBusinessRegionsOptions = generateOptions(
                normalizeConfigEntries(businessRegions),
                { title: 'title', value: 'id' },
                {
                    type: FILTERS.EXCLUDE_BUSINESS_REGION,
                    subtitle: filtersMap[CONST.EXCLUDE_BUSINESS_REGION]
                }
            );
            const linesOptions = generateOptions(
                normalizeConfigEntries(lines.data),
                { title: 'title', value: 'id', code: 'code' },
                {
                    type: FILTERS.LINE_ID,
                    subtitle: filtersMap[CONST.LINE_ID],
                }
            );
            const notificationOptions = generateOptions(
                normalizeConfigEntries(config.notificationTypes),
                { title: 'title', value: 'id' },
                {
                    type: FILTERS.TYPE,
                    subtitle: filtersMap[CONST.TYPE],
                }
            );
            const acknoledgedOptions = generateOptions(
                normalizeConfigEntries(ACKNOWLEDGED_STATUSES),
                { title: 'title', value: 'id' },
                {
                    type: FILTERS.ACKNOWLEDGED,
                    subtitle: filtersMap[CONST.ACKNOWLEDGED],
                }
            );
            const fromStopOptions = generateOptions(
                normalizeConfigEntries(stops.data),
                { title: 'title', value: 'id' },
                {
                    type: FILTERS.RIDE_FROM_ID,
                    subtitle: filtersMap[CONST.RIDE_FROM_ID],
                }
            );
            const toStopOptions = generateOptions(
                normalizeConfigEntries(stops.data),
                { title: 'title', value: 'id' },
                {
                    type: FILTERS.RIDE_TO_ID,
                    subtitle: filtersMap[CONST.RIDE_TO_ID],
                }
            );
            const concessionOwnersOptions = generateOptions(
                normalizeConfigEntries(concessionOwners.data),
                { title: 'title', value: 'id' },
                {
                    type: FILTERS.CONCESSION_OWNER_ID,
                    subtitle: filtersMap[CONST.CONCESSION_OWNER_ID],
                }
            );
            const excludeConcessionOwnerOptions = generateOptions(
                normalizeConfigEntries(concessionOwners.data),
                { title: 'title', value: 'id' },
                {
                    type: `not-${FILTERS.CONCESSION_OWNER_ID}`,
                    subtitle: filtersMap[CONST.EXCLUDE_CONCESSION_OWNER_ID],
                }
            );

            const optionsList = {
                [FILTERS.LINE_ID]: linesOptions,
                [FILTERS.TYPE]: notificationOptions,
                [FILTERS.ACKNOWLEDGED]: acknoledgedOptions,
                [FILTERS.RIDE_FROM_ID]: fromStopOptions,
                [FILTERS.RIDE_TO_ID]: toStopOptions,
                [FILTERS.CONCESSION_OWNER_ID]: concessionOwnersOptions,
                [`not-${FILTERS.CONCESSION_OWNER_ID}`]:
                    excludeConcessionOwnerOptions,
                [FILTERS.BUSINESS_REGION]: businessRegionsOptions,
                [FILTERS.EXCLUDE_BUSINESS_REGION]: excludeBusinessRegionsOptions,
            };

            const listPickerOptions = [].concat(
                linesOptions,
                notificationOptions,
                fromStopOptions,
            );

            dispatch({
                payload: {
                    data: {
                        ...optionsList,
                        listPickerOptions,
                    },
                    error: false,
                    pending: false,
                },
            });
        }
    }, [
        stops.data,
        lines.data,
        businessRegions.data,
        concessionOwners.data,
        filtersMap,
        config.notificationTypes,
    ]);

    const request = useCallback(
        (data, ...restArgs) => {
            dispatch({
                payload: {
                    error: false,
                    pending: true,
                },
            });
            getStops(data, ...restArgs);
            getLines(data, ...restArgs);
            getBusinessRegions(data, ...restArgs);
            getConcessionOwners(data, ...restArgs);
        },
        [getStops, getLines, getConcessionOwners, getBusinessRegions]
    );

    return [state, request];
}

function reducer(state, action) {
    const { payload } = action;
    return { ...state, ...payload };
}
