import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Icon, IconArrowDown } from '@flixbus/honeycomb-icons-react';

export default function ActiveItem(props) {
    const { icon, text, title, className } = props;
    const cssClass = classNames('language-switcher__active-lng', {
        [className]: className,
    });
    return (
        <span className={cssClass}>
            {icon ? (
                <img
                    src={icon}
                    className="language-switcher__img"
                    alt={title}
                />
            ) : null}
            {text}
            <Icon style={{ fill: 'white' }} InlineIcon={IconArrowDown} />
        </span>
    );
}

ActiveItem.propTypes = {
    icon: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    title: PropTypes.string,
    className: PropTypes.string,
};

ActiveItem.defaultProps = {
    title: '',
    className: '',
};
