import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FiltersProviderContext } from '../FiltersProvider';
import { Calendar, Input, Pager, PagerItem, SelectGroup, Spinner } from '@flixbus/honeycomb-react';
import usePrepareArchivedFilters from './hooks/usePrepareArchivedFilters';
import ListItem from './ListItem';
import BarBox from './BarBox';
import { TranslateContext } from '../../system/Translate';
import { useApi } from '../../api';
import { formatDate } from '../../utils/date';
import { Icon, IconArrowDown, IconCalendar } from '@flixbus/honeycomb-icons-react';
import { SortProviderContext, sortRules } from '../SortProvider';
import ResultsHeader from './ResultsHeader';
import { ScrollToTop } from "../ScrollToTop";
import parseNotification from "../../helpers/notificationsParser";

import './ArchivedResults.scss';

const DATE_FORMAT = 'YYYY-MM-DD';

const getEarliestArchivedNotificationsDate = () => {
    const timestamp = new Date().setDate(new Date().getDate() - 7);
    return new Date(timestamp);
};

const ArchivedResults = ({ rideViewUrl, busViewUrl, orderViewUrl, stationNameById }) => {
    const { filters } = useContext(FiltersProviderContext);
    const { translate } = useContext(TranslateContext);
    const isFirstRender = useRef(true);

    const { sort } = useContext(SortProviderContext);
    const [rule, direction] = sort.split(',');
    const [sortedNotifications, setSortedNotifications] = useState([]);

    const [isLocal, setIsLocal] = useState(true);

    const [showCalendar, setShowCalendar] = useState(false);
    const [selectedDate, setSelectedDate] = useState(() => {
        // set yesterday as initial date
        const date = new Date();
        date.setDate(date.getDate() - 1);
        return formatDate(date, DATE_FORMAT);
    });


    const [activePage, setActivePage] = useState(0);

    const [response, request] = useApi('post', `notifications/filtered?page=${activePage}&size=100`);

    const memoizedFilters = useMemo(
        () => ({
            ...filters,
            selectedDate,
        }),
        [filters, selectedDate]
    );
    const preparedFilters = usePrepareArchivedFilters(memoizedFilters);

    const calendarRef = useRef(null);

    const timezoneOptions = [
        {
            id: 'archived-station-time',
            value: 'station',
            name: 'archived-time-options',
            label: translate('station_time'),
            defaultChecked: true,
        },
        {
            id: 'archived-local-time',
            value: 'local',
            name: 'archived-time-options',
            label: translate('local_time'),
        },
    ];

    useEffect(() => {
        const notifications =  response?.data?.content.map(n => parseNotification(n));

        if (notifications && !response.pending) {
            setSortedNotifications(notifications.sort(sortRules[rule](direction)));
        }
    }, [sort, response]);

    // reset pagination on filters update
    useEffect(() => {
        setActivePage(0);
    }, [preparedFilters]);

    // request new data on pagination or filters update
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }

        setSortedNotifications([]);
        request(preparedFilters);
    }, [preparedFilters, activePage]);

    // close calendar on outside click
    useEffect(() => {
        function handleClickOutside(event) {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setShowCalendar(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleDateSelection = (date) => {
        const formattedDate = formatDate(date, DATE_FORMAT);
        setSelectedDate(formattedDate);
        setShowCalendar(false);
    };

    return (
        <div style={{ alignItems: 'center' }}>
            <div className="ac_archived-results__controls-container">
                <div>
                    <Input
                        aria-label="archived-calendar"
                        extraClasses="ac_archived-results__date-input"
                        placeholder="Select notifications date"
                        id="archived-date"
                        value={selectedDate}
                        readOnly
                        iconRight={<Icon InlineIcon={IconArrowDown} />}
                        iconLeft={<Icon InlineIcon={IconCalendar} />}
                        onFocus={() => setShowCalendar(true)}
                    />
                    {showCalendar && (
                        <div className="ac_archived-results__calendar-container" ref={calendarRef}>
                            <Calendar
                                extraClasses="ac_archived-results__calendar"
                                appearance="compact"
                                id="return-picker"
                                horizontal
                                startDate={getEarliestArchivedNotificationsDate()}
                                endDate={new Date()}
                                handleSelect={handleDateSelection}
                            />
                        </div>
                    )}
                </div>
                <SelectGroup
                    aria-label={translate('select_time')}
                    id="archived-timezone-switcher"
                    options={timezoneOptions}
                    onChange={(e) => setIsLocal(e.target.value)}
                />
            </div>
            {response.pending && sortedNotifications.length <= 0 ? (
                <div className="ac_archived-results__spinner">
                    <Spinner size="xl" />
                </div>
            ) : sortedNotifications.length > 0 ? (
                <>
                    <ResultsHeader sticky={false} />
                    {sortedNotifications.map((item) => (
                        <BarBox hoverStyle key={`wd-${item?.id}`} lineColor={item?.line?.lineColor} ws={item.ws}>
                            <ListItem
                                notification={item}
                                stationNameById={stationNameById}
                                rideViewUrl={rideViewUrl}
                                busViewUrl={busViewUrl}
                                orderViewUrl={orderViewUrl}
                                isLocal={isLocal}
                            />
                        </BarBox>
                    ))}

                    <Pager aria-label="archived-pagination" extraClasses="ac_archived-results__pagination">
                        {new Array(response.data.totalPages).fill().map((_, index) => (
                            <PagerItem
                                onClick={() => {
                                    setActivePage(index);
                                }}
                                active={activePage === index}
                                key={index}
                            >
                                {index + 1}
                            </PagerItem>
                        ))}
                    </Pager>
                    <ScrollToTop />
                </>
            ) : (
                <p>{translate('no_notifications')}</p>
            )}
        </div>
    );
};

export default ArchivedResults;
