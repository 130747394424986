import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import EmptyItem from './EmptyItem';

export default function BarBox({
    loading,
    lineColor = '080808',
    hoverStyle,
    children,
    ws,
}) {
    const cssClass = classNames('bar-box', {
        'bar-box--with-hover': hoverStyle,
        'bar-box--animate': ws,
    });
    const style = {
        boxShadow: `inset -4px 0px 0px #${lineColor}, inset 4px 0px 0px #${lineColor}`,
    };
    return (
        <div className={cssClass} style={style}>
            {loading ? <EmptyItem /> : children}
        </div>
    );
}

BarBox.propTypes = {
    hoverStyle: PropTypes.bool.isRequired,
    loading: PropTypes.bool.isRequired,
};
BarBox.defaultProps = { loading: false, hoverStyle: false };
