import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const RideInfoTag = (props) => {
    const { children, type, lineColor, small } = props;
    const itemClassName = classNames(['ride-info-tag', `ride-info-tag--${type}`], { 'ride-info-tag--small': small });
    const style = type === 'colored' ? { borderColor: `#${lineColor}` } : null;
    return (
        <div className={itemClassName} style={style}>
            {children}
        </div>
    );
};

export default RideInfoTag;

RideInfoTag.propTypes = {
    type: PropTypes.string,
};

RideInfoTag.defaultProps = {
    type: 'default',
};
