import React, { useState, useContext } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Button, SelectGroup } from '@flixbus/honeycomb-react';
import BarBox from './BarBox';
import ListItem from './ListItem';
import ResultsSpinner from './ResultsSpinner';
import StickyWrapper from './StickyWrapper';
import ResultsHeader from './ResultsHeader';
import useSticky from '../../hooks/useSticky';
import { TranslateContext } from '../../system/Translate';
import {ScrollToTop} from "../ScrollToTop";

export default React.memo((props) => {
    const { loading, notifications, stationNameById, rideViewUrl, busViewUrl, orderViewUrl, loadMore, isLoadMore } =
        props;
    const { translate } = useContext(TranslateContext);
    const [isLocal, setIsLocal] = useState(true);
    const { isSticky, element } = useSticky();

    const timezoneOptions = [
        {
            id: 'station-time',
            value: 'station',
            name: 'time-options',
            label: translate('station_time'),
            defaultChecked: true,
        },
        {
            id: 'local-time',
            value: 'local',
            name: 'time-options',
            label: translate('local_time'),
        },
    ];

    return loading && notifications?.length <= 0 ? (
        <ResultsSpinner loading={loading} />
    ) : notifications?.length > 0 ? (
        <>
            <SelectGroup
                aria-label={translate('select_time')}
                id="timezone-switcher"
                options={timezoneOptions}
                onChange={(e) => setIsLocal(e.target.value)}
            />
            <ResultsHeader sticky={isSticky} />
            <StickyWrapper element={element}>
                <AnimatePresence>
                    {notifications.map((item) => (
                        <motion.div key={`wd-${item?.id}-motion`} exit={{ opacity: 0 }}>
                            <BarBox hoverStyle key={`wd-${item?.id}`} lineColor={item?.line?.lineColor} ws={item.ws}>
                                <ListItem
                                    notification={item}
                                    stationNameById={stationNameById}
                                    rideViewUrl={rideViewUrl}
                                    busViewUrl={busViewUrl}
                                    orderViewUrl={orderViewUrl}
                                    isLocal={isLocal}
                                />
                            </BarBox>
                        </motion.div>
                    ))}
                </AnimatePresence>
            </StickyWrapper>
            {isLoadMore && (
                <div style={{ textAlign: 'center', padding: '20px 0 50px 0' }}>
                    <Button disabled={loading} appearance="tertiary" loading={loading} onClick={loadMore}>
                        {translate('load_more')}
                    </Button>
                </div>
            )}
            <ScrollToTop />
        </>
    ) : (
        <p>{translate('no_notifications')}</p>
    );
});
