import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Icon, IconStation, IconSwitch } from '@flixbus/honeycomb-icons-react';
import { ListPicker } from '.';

export default function ComboStopsPicker(props) {
    const { ids, labels, actions, options, loading, values } = props;
    const [rotate, setRotate] = useState(false);
    function swapValues() {
        actions[0](values[1]);
        actions[1](values[0]);
        setRotate(!rotate);
    }
    return (
        <div className="rs-combo-picker">
            <div className="rs-combo-picker__left">
                <ListPicker
                    id={ids[0]}
                    label={labels[0]}
                    InlineIcon={IconStation}
                    options={options}
                    onChange={actions[0]}
                    value={values[0]}
                />
            </div>
            <div className="rs-combo-picker__right">
                <ListPicker
                    id={ids[1]}
                    label={labels[1]}
                    options={options}
                    onChange={actions[1]}
                    loading={loading}
                    value={values[1]}
                />
            </div>
            <div
                className={`rs-combo-picker__switch ${
                    rotate ? 'rs-combo-picker__switch--rotate' : ''
                }`}
                onClick={swapValues}
            >
                <Icon InlineIcon={IconSwitch} />
            </div>
        </div>
    );
}
ComboStopsPicker.propTypes = {
    ids: PropTypes.array.isRequired,
    labels: PropTypes.array.isRequired,
    actions: PropTypes.arrayOf(PropTypes.func).isRequired,
    options: PropTypes.array,
    loading: PropTypes.bool,
    values: PropTypes.array,
};
ComboStopsPicker.defaultProps = { options: [], loading: false, values: [] };
