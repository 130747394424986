import parseNotification from '../../helpers/notificationsParser';

class WDMapper {
    constructor() {
        this.wdMap = new Map();
    }
    set(notifications, skip = false) {
        notifications.forEach((n) => {
            if (this.wdMap.get(n.id) !== undefined && skip) {
                return;
            }
            this.wdMap.set(n.id, parseNotification(n));
        });
    }
    update(notification) {
        this.wdMap.set(notification.id, parseNotification(notification));
        return this;
    }
    merge(data) {
        const originNotification = this.wdMap.get(data.id);
        if (originNotification === undefined) {
            return this;
        }
        this.wdMap.set(
            data.id,
            parseNotification({ ...originNotification, ...data })
        );
        return this;
    }
    getArray() {
        return [...this.wdMap.values()];
    }

    reset() {
        this.wdMap.clear();
    }
}

export default WDMapper;
