import { useApi } from '../../../api';
import { useEffect, useState } from "react";

export default function useBusinessRegions() {
    const [response, request] = useApi('get', '/options/line-regions');
    const [transformedResponse, setTransformedResponse] = useState({});

    useEffect(() => {
        if (response?.data) {
            const transformed = response.data.reduce((acc, { id, name }) => {
                acc[id] = name;
                return acc;
            }, {});
            setTransformedResponse(transformed);
        }
    }, [response]);

    return [transformedResponse, request];
}